import React from 'react';
import styled from "styled-components";
import vision from "../../images/vision.png";
import value_01 from "../../images/value_01.png";
import value_02 from "../../images/value_02.png";
import value_03 from "../../images/value_03.png";
import value_04 from "../../images/value_04.png";
import value_05 from "../../images/value_05.png";


function Vision() {
    return (
        <>
            <InfoWhiteBox>
                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>Vision</h1>
                        </div>
                        <p>기술(
                            <span>T</span>
                            echnology) 정보, 기업(
                            <span>E</span>
                            nterprise)정보, 신용(
                            <span>C</span>
                            redit)정보<br/> 및 전문가(
                            <span>H</span>
                            uman resource) 정보에 대한 최고의 전문성을<br/> 바탕으로 기술경영 인사이트 전문기업으로 성장하는데<br/> 최선의 노력을 하고 있습니다.
                        </p>
                    </TextBox>
                    <ImageBox>
                        <img src={vision} alt="vision"/>
                    </ImageBox>
                </InfoBox>
            </InfoWhiteBox>
            <InfoGreyBox>
                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>핵심가치</h1>
                        </div>
                        <p><span>테크디엔에이 5대 핵심가치</span>는</p>
                        <p>회사의 본질적인 원칙이자 기준입니다.</p>
                        <p>모든 구성원들이 업무와 생활에서 핵심가치를 적용하고 실천함으로써</p>
                        <p>새로게 혁신하는 우리 모두의 행복한 기업이 되겠습니다.</p>
                    </TextBox>
                    <ValueImageBox>
                        {/*<img src={value} alt="value"/>*/}
                        <img src={value_01} alt="고객중심"/>
                        <img src={value_02} alt="도전과 전문성"/>
                        <img src={value_03} alt="소통과 협력"/>
                        <img src={value_04} alt="인재제일"/>
                        <img src={value_05} alt="주인의식"/>
                    </ValueImageBox>
                </InfoBox>
            </InfoGreyBox>
        </>
    );
}

export default Vision;


const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 100px 10px;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 20vh;
    padding: 50px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }

  div {
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoGreyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //max-width: 1200px;
  min-height: 65vh;
  padding: 100px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
    padding: 50px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }

  div {
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
`;

const TextBox = styled.div`
  padding: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.mobileS} {
    padding: 0 5px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: black;
      letter-spacing: -2px;
      font-size: 4rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      margin-bottom: 4rem;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 2rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  p {
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #919191;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 0.85rem;
      line-height: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }

  span {
    color: #EA5413;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    margin-top: 1rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 0.85rem;
      line-height: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100%;
  img {
    width: 900px;
    margin: 50px 0;
    @media ${({theme}) => theme.device.tabletL} {
      width: 650px;
      margin: 20px 0;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 100%;
      margin: 10px 0;
    }
  }
`;

const ValueImageBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 40px 20px 20px;
  img {
    width: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    @media ${({theme}) => theme.device.mobile} {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px 30px;
    }
    @media ${({theme}) => theme.device.mobileS} {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px;
    }
  }
`;
