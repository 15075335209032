import React, {useState} from 'react';
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import AboutUsBanner from "../components/AboutUs/AboutUsBanner";
import AboutUsContents from "../components/AboutUs/AboutUsContents";
import partner_01 from "../images/partner_01.png"
import partner_02 from "../images/partner_02.png"
import partner_03 from "../images/partner_03.png"
import partner_04 from "../images/partner_04.png"
import partner_05 from "../images/partner_05.png"
import partner_06 from "../images/partner_06.png"
import partner_07 from "../images/partner_07.png"
import partner_08 from "../images/partner_08.png"
import partner_09 from "../images/partner_09.png"
import partner_10 from "../images/partner_10.png"
import partner_11 from "../images/partner_11.png"
import partner_12 from "../images/partner_12.png"


function AboutUs() {
    const navigate = useNavigate();
    // url에서 쿼리 가져와서 변수 만들기
    let sch = useLocation().search;
    let query = new URLSearchParams(sch);
    let category = query.get('category');

    const basicURL = `/aboutus`;

    const items = [
        {
            type: 'vision',
            title: '비전&가치'
        },
        {
            type: 'info',
            title: '수상 및 인증'
        },
        {
            type: 'history',
            title: '주요연혁'
        },
        // {
        //     type: 'member',
        //     title: 'MEMBER'
        // },
        {
            type: 'PR',
            title: '홍보센터'
        }
    ];
    const [select, setSelect] = useState(category);

    const onClick = (type) => {
        setSelect(type);
        navigate(`${basicURL}${'?category='}${type}`);
    };

    return (
        <>
            <Header />
            <AboutUsBanner category={category}/>
            <SubMenu>
                <ul>
                    {items.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => onClick(item.type)}
                            className={`${select === item.type ? 'select' : ''}`}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
            </SubMenu>

            <AboutUsContents category={category}/>

            <AnimatedBox>
                <Track>
                    <Content>
                        <img src={partner_01} alt="partner_01"/>
                        <img src={partner_02} alt="partner_02"/>
                        <img src={partner_03} alt="partner_03"/>
                        <img src={partner_04} alt="partner_04"/>
                        <img src={partner_05} alt="partner_05"/>
                        <img src={partner_06} alt="partner_06"/>
                        <img src={partner_07} alt="partner_07"/>
                        <img src={partner_08} alt="partner_08"/>
                        <img src={partner_09} alt="partner_09"/>
                        <img src={partner_10} alt="partner_10"/>
                        <img src={partner_11} alt="partner_11"/>
                        <img src={partner_12} alt="partner_12"/>
                    </Content>
                </Track>
            </AnimatedBox>

            <Footer />
        </>
    );
}
export default AboutUs;

const SubMenu = styled.section`
  display: flex;
  justify-content: center;
  font-weight: 500;
  background-color: rgb(3, 9, 21);
  color: #fff;
  ul{
    max-width: 1200px;
    display: flex;
    justify-content: center;
    background-color: rgb(3, 9, 21);
    li{
      min-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 20px;
      color: #fff;
      cursor: pointer;
      @media ${({theme}) => theme.device.tabletL} {
        min-width: 150px;
        padding: 30px 8px;
        font-size: 15px;
      }
      @media ${({theme}) => theme.device.tablet} {
        min-width: 90px;
        padding: 20px 8px;
        font-size: 12px;
      }
      @media ${({theme}) => theme.device.mobile} {
        min-width: 58px;
        padding: 20px 1px;
        font-size: 10.5px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        min-width: 55px;
        padding: 14px 0px;
        font-size: 8px;
      }
    }
    .select {
      background-color: #fff;
      color:  rgb(3, 9, 21);
    }
  }
  ul li:hover {
    color: rgb(234, 84, 19);
  }
`;

const AnimatedBox = styled.div`
  background-color: #fff;
  border-top: 1px solid rgb(221, 221, 221);
position: relative; 
  width: 100%;
  max-width:100%; 
  height: 200px;
  overflow-x: hidden; 
  overflow-y: hidden;
  @keyframes marquee {
    from { transform: translateX(0); }
    to { transform: translateX(-50%); }
  }
  @media ${({theme}) => theme.device.tabletL} {
    height: 150px;
  }
  @media ${({theme}) => theme.device.tablet} {
    height: 100px;
  }
`;

const Track = styled.div`
  position: absolute; 
  white-space: nowrap;
  will-change: transform;
  animation: marquee 60s linear infinite;
  display: flex;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
img{
  margin: 0 20px;
  @media ${({theme}) => theme.device.tabletL} {
    width: 20%;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin: 0 10px;
  }
}
`;


