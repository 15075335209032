import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import banner_01 from "../../images/banner_01.jpg"
import banner_02 from "../../images/banner_02.jpg"
import banner_03 from "../../images/banner_03.jpg"

function MainBanner() {

    const mainBanner = [
        {
            "id": 1,
            "title_2": "“Technology DNA” 수집·분석,\n 다양한 기술경영학적 인사이트 제공",
            "sub_title": "㈜테크디엔에이는 빅데이터 기업으로써, 특허(기술)·시장 정보를 기반으로 \n기업·전문가의 “Technology DNA”를 수집하고 분석하여 매우 객관적이고도\n 다양한 기술경영학적 인사이트를 제공합니다.",
            "src": banner_01
        },
        {
            "id": 2,
            "title_1": "T \nE \nC \nH",
            "title_2": "echnology \nnterprise \nredit  \numan resource",
            "sub_title": "기술(Technology)정보, 기업(Enterprise)정보, 신용(Credit)정보 및 \n 전문가(Human resource)정보를 활용한 최고의 전문성을 바탕으로\n 기술경영정보 전문기업으로 성장하는데 최선의 노력을 하고 있습니다.",
            "src": banner_02
        },
        {
            "id": 3,
            "title_2": "빅데이터 분석을 통해 기업/전문가의 \n기술 포트폴리오 정보를 제공",
            "sub_title": "기업이 기술개발 과정에서 만들어내는 각종 흔적들을 바탕으로 빅데이터 분석을\n 통해 기업/전문가의 기술 포트폴리오 정보를 제공하고 있습니다.",
            "src": banner_03
        }
    ]

    const settings = {
        dots: false, // 스크롤바 아래 점으로 페이지네이션 여부
        infinite: true, //무한 반복 옵션
        speed: 500, // 다음 버튼 누르고 다음 화면 뜨는데까지 걸리는 시간(ms)
        autoplay: true, // 자동 스크롤 사용 여부
        autoplatSpeed: 3000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
        slidesToShow: 1, // 한 화면에 보여질 컨텐츠 개수
        sliedsToScroll: 1, //스크롤 한번에 움직일 컨텐츠 개수
        centerMode: true,
        centerPadding: '0px', // 0px 하면 슬라이드 끝쪽 이미지가 안잘림
        draggable: true, //드래그 가능 여부
        pauseOnHover: true, // 슬라이드 이동	시 마우스 호버하면 슬라이더 멈추게 설정
    };

    return (
        <Container>
            <StyledSlider {...settings}>
                {mainBanner.map((main, idx) => {
                    return (
                        <Banner key={idx}>
                            <div>
                                <TitleBox>
                                    <h1>{main.title_1}</h1>
                                    <h2>{main.title_2}</h2>
                                    <SubTitle>
                                        <p>{main.sub_title}</p>
                                    </SubTitle>
                                </TitleBox>
                            </div>
                            <img src={main.src} alt="banner_img"/>
                        </Banner>
                    );
                })}
            </StyledSlider>
        </Container>
    );
}

const Container = styled.div`
  background-color: #AAABB3;
  background-image: url(${banner_01});
  height: 847px;
  width: 100%;
  @media ${({theme}) => theme.device.tabletL} {
    height: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    height: 400px;
  }
  @media ${({theme}) => theme.device.mobile} {
    height: 300px;
  }
`;


const StyledSlider = styled(Slider)`
  display: flex;
  flex-direction: column;
  
  .slick-list {
    width: 100%;
    height: 847px;
    margin: 0 auto;
    @media ${({theme}) => theme.device.tabletL} {
      height: 500px;
    }
    @media ${({theme}) => theme.device.tablet} {
      height: 400px;
    }
    @media ${({theme}) => theme.device.mobile} {
      height: 300px;
    }
  }
  .slick-slide div {}
  .slick-prev {
    left: 50px;
    position: absolute;
    z-index: 90;
    @media ${({theme}) => theme.device.tablet} {
      left: 30px;
    }
    @media ${({theme}) => theme.device.mobile} {
      left: 10px;
    }
  }
  .slick-prev:before {
    font-size: 50px;
    //color: black;
    color: #fff;
    @media ${({theme}) => theme.device.tablet} {
      font-size: 40px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 30px;
    }
  }
  .slick-next {
    right: 60px;
    position: absolute;
    z-index: 90;
    @media ${({theme}) => theme.device.tablet} {
      right: 50px;
    }
    @media ${({theme}) => theme.device.mobile} {
      right: 10px;
    }
  }
  
  .slick-next:before {
    font-size: 50px;
    //color: black;
    color: #fff;
    @media ${({theme}) => theme.device.tablet} {
      font-size: 40px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 30px;
    }
  }
  
`;

const Banner = styled.div`
  //position: relative;
  //background-color: #d5d4da;
  //width: 100%;
  //border: 5px solid saddlebrown;
  div {
    position: absolute;
    //margin: 200px 200px;
    //width: 100%;

    //margin: 100px;
    color: #fff;
    white-space:pre-wrap;
    animation: fadeInUp 1.5s;
    display: flex;
       @keyframes fadeInUp {
         0% {
           opacity: 0;
           transform: translate3d(0, 100%, 0);
         }
         to {
           opacity: 1;
           transform: translateZ(0);
         }
       }
  }
  img {
    //height: 650px;
    width: 100%;
    height: 847px;
    object-fit: cover;
    @media ${({theme}) => theme.device.tabletL} {
      height: 500px;
    }
    @media ${({theme}) => theme.device.tablet} {
      height: 400px;
    }
    @media ${({theme}) => theme.device.mobile} {
      height: 300px;
    }
  }
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 1200px;
  max-width: 1740px;
  margin: 200px;
  @media ${({theme}) => theme.device.tabletL} {
    margin: 120px;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin: 80px;
  }
  @media ${({theme}) => theme.device.mobile} {
    margin: 40px;
  }
  h1 {
    font-size: 52px;
    font-weight: bold;
    line-height: 70px;
    color: rgb(234, 84, 19);
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 38px;
      line-height: 60px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 25px;
      line-height: 40px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 15px;
      line-height: 25px;
    }
  }

  h2 {
    font-size: 52px;
    font-weight: bold;
    line-height: 70px;
    color: #ffffff;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 38px;
      line-height: 60px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 25px;
      line-height: 40px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 15px;
      line-height: 25px;
    }
  }
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  //width: 100%;
  min-width: 1200px;
  max-width: 1740px;
  //margin: 150px 200px;
  //margin: 0px;
  padding-top: 340px;
  @media ${({theme}) => theme.device.tablet} {
    padding-top: 260px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding-top: 220px;
  }
  p {
    //padding-top: 320px;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    @media ${({theme}) => theme.device.tabletL} {
      width: 600px;
      font-size: 13px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 360px;
      font-size: 9px;
      line-height: 20px;
    }
    @media ${({theme}) => theme.device.mobile} {
      width: 200px;
      font-size: 5px;
      line-height: 15px;
    }
    @media ${({theme}) => theme.device.mobileS} {
      width: 170px;
      font-size: 5px;
      line-height: 15px;
    }
  }

`;
export default MainBanner;