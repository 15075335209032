import React from 'react';
import styled from "styled-components";
import PR_img from "../../images/PR_img.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";


function PR() {

// 사이트 이동 Url 변수
    const goPRCenter = "https://blog.naver.com/prologue/PrologueList.naver?blogId=techdna"

    return (
        <>
            <InfoWhiteBox>
                <InfoBox>
                    {/*<TextBox>*/}
                    {/*    <div>*/}
                    {/*        <h1>홍보센터</h1>*/}
                    {/*    </div>*/}
                    {/*</TextBox>*/}
                    <ImageBox onClick={()=>{window.open(goPRCenter)}}>
                        <img src={PR_img} alt="PR_img"/>
                    </ImageBox>
                    <button onClick={()=>{window.open(goPRCenter)}}>
                        <span>홍보센터<p>바로가기</p></span>
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </button>

                </InfoBox>
            </InfoWhiteBox>
        </>
    );
}

export default PR;


const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 100px 10px;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 20vh;
    padding: 50px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }

  div {
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
      button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 260px;
      background-color: rgb(234, 84, 19);
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      margin: 30px;
      transition: all 0.5s linear;
      @media ${({theme}) => theme.device.tabletL} {
        margin-top: 50px;
      }
      @media ${({theme}) => theme.device.tablet} {
        width: 200px;
        padding: 10px;
        font-size: 14px;
      }
      @media ${({theme}) => theme.device.mobile} {
        width: 170px;
        padding: 10px;
        font-size: 10px;
        margin-top:10px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        width: 150px;
      }
      &:hover {
        transform: translateX( 20px )
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;

        p {
          color: white;
          font-size: 16px;
          padding: 0 0 0 5px;
          @media ${({theme}) => theme.device.tablet} {
            font-size: 14px;
          }
          @media ${({theme}) => theme.device.mobile} {
            font-size: 10px;
          }
        }
      }
    }
  
`;

// const TextBox = styled.div`
//   padding: 0 10px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   @media ${({theme}) => theme.device.mobileS} {
//     padding: 0 5px;
//   }
//
//   div {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//
//     h1 {
//       color: black;
//       letter-spacing: -2px;
//       font-size: 4rem;
//       font-weight: 700;
//       line-height: 5rem;
//       text-align: center;
//       margin-bottom: 4rem;
//       @media ${({theme}) => theme.device.tabletL} {
//         font-size: 2rem;
//         line-height: 3rem;
//         margin-bottom: 2rem;
//       }
//       @media ${({theme}) => theme.device.tablet} {
//         font-size: 1.5rem;
//         line-height: 2rem;
//         margin-bottom: 1.5rem;
//       }
//       @media ${({theme}) => theme.device.mobile} {
//         font-size: 1rem;
//         margin-bottom: 1rem;
//       }
//     }
//   }
//
//   p {
//     text-align: center;
//     font-size: 2rem;
//     font-weight: 400;
//     line-height: 3rem;
//     color: #919191;
//     @media ${({theme}) => theme.device.tabletL} {
//       font-size: 1.5rem;
//       line-height: 2rem;
//     }
//     @media ${({theme}) => theme.device.tablet} {
//       font-size: 0.85rem;
//       line-height: 1.5rem;
//     }
//     @media ${({theme}) => theme.device.mobile} {
//       font-size: 0.5rem;
//       line-height: 1rem;
//     }
//   }
//
//   span {
//     color: #EA5413;
//     font-size: 2rem;
//     font-weight: 400;
//     line-height: 3rem;
//     margin-top: 1rem;
//     text-align: center;
//     @media ${({theme}) => theme.device.tabletL} {
//       font-size: 1.5rem;
//       line-height: 2rem;
//     }
//     @media ${({theme}) => theme.device.tablet} {
//       font-size: 0.85rem;
//       line-height: 1.5rem;
//     }
//     @media ${({theme}) => theme.device.mobile} {
//       font-size: 0.5rem;
//       line-height: 1rem;
//     }
//   }
// `;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    width: 600px;
    @media ${({theme}) => theme.device.tabletL} {
      width: 500px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 300px;
    }
    @media ${({theme}) => theme.device.mobile} {
      width: 270px;
    }
    @media ${({theme}) => theme.device.mobileS} {
      width: 250px;
    }
  }
`;

