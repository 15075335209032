import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

// 페이지 이동 시 화면의 최상단으로 위치
export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(()=> {
        window.scrollTo(0,0);
    },[pathname])

    return null;
}
