import React,{useRef} from 'react';
import styled from "styled-components";
import videoUrl from "../../video/research-all-introduction_230723.mp4";
import poster from "../../images/researchall_poster.png";
// import researchall_img_01 from "../../images/researchall_img_01.png";
// import researchall_img_02 from "../../images/researchall_img_02.png";
// import researchall_img_03 from "../../images/researchall_img_03.png";
import researchall_img_04 from "../../images/researchall_img_04.png";
import researchall_img_05 from "../../images/researchall_img_05.png";
import researchall_img_06 from "../../images/researchall_img_06.png";
import researchall_service_img_01 from "../../images/researchall_service_img_01.png";
import researchall_service_img_02 from "../../images/researchall_service_img_02.png";
import researchall_service_img_03 from "../../images/researchall_service_img_03.png";
import researchall_service_img_04 from "../../images/researchall_service_img_04.png";
import researchall_service_img_05 from "../../images/researchall_service_img_05.png";
import researchall_service_img_06 from "../../images/researchall_service_img_06.png";

import researchall_service_detail_img_01 from "../../images/researchall_service_detail_img_01.png";
import researchall_service_detail_img_02 from "../../images/researchall_service_detail_img_02.gif";
import researchall_service_detail_img_03 from "../../images/researchall_service_detail_img_03.png";
import researchall_service_detail_img_04 from "../../images/researchall_service_detail_img_04.gif";
import researchall_service_detail_img_05 from "../../images/researchall_service_detail_img_05.png";
import researchall_service_detail_img_06 from "../../images/researchall_service_detail_img_06.gif";

import useScrollFadeIn from '../useScrollFadeIn'

function Researchall() {

    const animatedItem = {
        0: useScrollFadeIn('up', 1, 0.1),
        1: useScrollFadeIn('up', 1, 0.1),
        2: useScrollFadeIn('up', 1, 0.1),
        3: useScrollFadeIn('up', 1, 0.1),
    };

    // const mainFunction = [
    //     {
    //         title: '스마트 검색 엔진',
    //         text: '자동번역 및 연산자 유사어 최적생성,특허용 용어로 생성 번역어·복합어·대역어까지 추적가능',
    //         img: researchall_service_img_01,
    //     },
    //     {
    //         title: '데이터 정비',
    //         text: '검색의도 가중치 반영 유효/노이즈 선택 관련도 산출 전문가 검증 출원인/국적 정비 엔진',
    //         img: researchall_service_img_02,
    //     },
    //     {
    //         title: '분석지표 결과 제공',
    //         text: '자동화된 특허 분석 플랫폼을 통한 국내 최다 분석지표/가시화 결과제공(키워드분석 · 출원인분석 · 동향분석 · 지표분석 · 선도기업분석)',
    //         img: researchall_service_img_03,
    //     },
    //     {
    //         title: '자동 특허 분석 보고서',
    //         text: '분석대상 기술, 특정 기업별 특허 포트폴리오 자동 분석 및 종합 분석을 통한 인사이트 제공',
    //         img: researchall_service_img_05,
    //     },
    //     {
    //         title: '최신연구정보 수집·분석',
    //         text: '지식정보 통합 검색 (용어사전 · 뉴스 · 논문 · 연구보고서 · R&D 과제)결과를 자동 수집하고 프로젝트를 통합관리 가능',
    //         img: researchall_service_img_04,
    //     },
    //     {
    //         title: '지속적인 업데이트',
    //         text: '프로젝트 문헌 (용어사전 · 뉴스 · 논문 · 연구보고서 · R&D 과제)을 일 단위로 업데이트 하여 지속적인 관리가 가능',
    //         img: researchall_service_img_06,
    //     },
    // ];


    /* 스크롤 이동 */
    const inputForm1 = useRef();  //특정 DOM을 가리킬 때 사용하는 Hook함수, SecondDiv에 적용
    const inputForm2 = useRef();
    const inputForm3 = useRef();
    const inputForm4 = useRef();
    const inputForm5 = useRef();
    const inputForm6 = useRef();

    const onMoveToForm1 = () => {inputForm1.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};
    const onMoveToForm2 = () => {inputForm2.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};
    const onMoveToForm3 = () => {inputForm3.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};
    const onMoveToForm4 = () => {inputForm4.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};
    const onMoveToForm5 = () => {inputForm5.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};
    const onMoveToForm6 = () => {inputForm6.current.scrollIntoView({ behavior: 'smooth', block: 'start' });};
    return (
        <>
            <IntroBox>
                <Title><p>ResearchAll</p></Title>
                <VideoBox >
                    <video src={videoUrl} poster={poster} loop controls />
                </VideoBox>
            </IntroBox>
            <Divide />
            <InfoWhiteBox>
                <div>
                    <TextBox  {...animatedItem[1]}>
                        <div>
                            <h1>R&D 기획, 수행 과정에서 필요한</h1>
                            <h2>스마트한 특허/논문분석</h2>
                        </div>
                        <p>
                            300만원 이하의 합리적인 가격!<br/> 5영업일 이내로 빠르게,<br/>
                            객관적인 데이터를 기반(데이터 클라우드 서비스)으로<br/>
                            특허(논문) 분석을 제공해 드립니다.
                        </p>
                        <span>* R&D 연구개발 비용 – 직접비 상계 가능</span>
                    </TextBox>
                    <ImageBox>
                        <img src={researchall_img_04} alt="researchall_img_04"/>
                    </ImageBox>
                </div>
            </InfoWhiteBox>
            <InfoGreyBox>
                <div>
                    <ImageBox>
                        <img src={researchall_img_05} alt="researchall_img_05"/>
                    </ImageBox>
                    <TextBox  {...animatedItem[2]}>
                        <div>
                            <h1>최단시간 특허</h1>
                            <h2>자동 리포팅, 경쟁사 분석</h2>
                        </div>
                        <p>
                            연구자(대학원생)이 직접 작성/수정 가능한 <br/>자동 리포팅
                            기능을 통해<br/> 맞춤형 연구정보 분석 리포팅이 가능합니다.
                        </p>
                    </TextBox>
                </div>
            </InfoGreyBox>
            <InfoWhiteBox>
                <div>
                    <TextBox  {...animatedItem[3]}>
                        <div>
                            <h1>다양한 정량/정성 분석을 통한</h1>
                            <h2> 인사이트 발굴</h2>
                        </div>
                        <p>
                            연구개발 계획서 작성, 동향 논문 작성 및<br/>
                            다양한 인사이트 도출을 위한<br/>
                            특허를 이용한 지표분석 결과, 동향 그래프,<br/> 주요 플레이어 및
                            핵심정보를 제공합니다.
                        </p>
                    </TextBox>
                    <ImageBox>
                        <img src={researchall_img_06} alt="researchall_img_06"/>
                    </ImageBox>
                </div>
            </InfoWhiteBox>
                <ServiceBox>
                    <TitleBox>
                        <h1>주요기능</h1>
                    </TitleBox>
                    <ServiceWrap>
                        {/*{mainFunction.map((fcn, idx) => (*/}
                        {/*    <div key={idx}*/}
                        {/*         onClick={onMoveToForm}*/}
                        {/*    >*/}
                        {/*        <img src={fcn.img} alt={fcn.title} />*/}
                        {/*        <h1>{fcn.title}</h1>*/}
                        {/*        <span>{fcn.text}</span>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                            <div onClick={onMoveToForm1}>
                                <img src={researchall_service_img_01} alt="스마트 검색 엔진" />
                                <h1>스마트 검색 엔진</h1>
                                <span>자동번역 및 연산자 유사어 최적생성,특허용 용어로 생성 번역어·복합어·대역어까지 추적가능</span>
                            </div>
                        <div onClick={onMoveToForm2}>
                            <img src={researchall_service_img_02} alt="데이터 정비" />
                            <h1>데이터 정비</h1>
                            <span>검색의도 가중치 반영 유효/노이즈 선택 관련도 산출 전문가 검증 출원인/국적 정비 엔진</span>
                        </div>
                        <div onClick={onMoveToForm3}>
                            <img src={researchall_service_img_03} alt="분석지표 결과 제공" />
                            <h1>분석지표 결과 제공</h1>
                            <span>자동화된 특허 분석 플랫폼을 통한 국내 최다 분석지표/가시화 결과제공(키워드분석 · 출원인분석 · 동향분석 · 지표분석 · 선도기업분석)</span>
                        </div>
                        <div onClick={onMoveToForm4}>
                            <img src={researchall_service_img_05} alt="자동 특허 분석 보고서" />
                            <h1>자동 특허 분석 보고서</h1>
                            <span>분석대상 기술, 특정 기업별 특허 포트폴리오 자동 분석 및 종합 분석을 통한 인사이트 제공</span>
                        </div>
                        <div onClick={onMoveToForm5}>
                            <img src={researchall_service_img_04} alt="최신연구정보 수집·분석" />
                            <h1>최신연구정보 수집·분석</h1>
                            <span>지식정보 통합 검색 (용어사전 · 뉴스 · 논문 · 연구보고서 · R&D 과제)결과를 자동 수집하고 프로젝트를 통합관리 가능</span>
                        </div>
                        <div onClick={onMoveToForm6}>
                            <img src={researchall_service_img_06} alt="지속적인 업데이트" />
                            <h1>지속적인 업데이트</h1>
                            <span>프로젝트 문헌 (용어사전 · 뉴스 · 논문 · 연구보고서 · R&D 과제)을 일 단위로 업데이트 하여 지속적인 관리가 가능</span>
                        </div>


                    </ServiceWrap>
                    <Divide ref={inputForm1}/>
                    <InfoWhiteBox>
                        <DetailServiceBox>
                                <div>
                                    <h1>스마트 검색 엔진</h1>
                                    <p>기존의 특허 검색식은 매우 낯설고 그 연산을 짜기 복잡합니다. <br/>리서치올은 특허 검색을 처음 접하는 사람도 쉽고 간편하게<br/> 접근할 수 있도록 [스마트 검색] 기능을 제공합니다. </p>
                                </div>
                            <img src={researchall_service_detail_img_01} alt="researchall_service_detail_img_01"/>
                        </DetailServiceBox>
                    </InfoWhiteBox>
                    <Divide ref={inputForm2}/>
                    <InfoWhiteBox>
                        <DetailServiceBox>
                            <div>
                                <h1>데이터 정비</h1>
                                <p>검색의도 가중치 반영 유효/<br/>노이즈 선택 관련도 산출 전문가 검증 출원인/국적 정비 엔진</p>
                            </div>
                            <img src={researchall_service_detail_img_02} alt="researchall_service_detail_img_02"/>
                        </DetailServiceBox>
                    </InfoWhiteBox>
                    <Divide ref={inputForm3}/>
                    <InfoWhiteBox>
                        <DetailServiceBox>
                            <div>
                                <h1>분석지표 결과 제공</h1>
                                <p>자동화된 특허 분석 플랫폼을 통한 국내 최다 분석지표/가시화 결과제공<br/>(키워드분석 · 출원인분석 · 동향분석 · 지표분석 · 선도기업분석)</p>
                            </div>
                            <img src={researchall_service_detail_img_03} alt="researchall_service_detail_img_03"/>
                        </DetailServiceBox>
                    </InfoWhiteBox>
                    <Divide ref={inputForm4}/>
                    <InfoWhiteBox>
                        <DetailServiceBox>
                            <div>
                                <h1>자동 특허 분석 보고서</h1>
                                <p>분석대상 기술, 특정 기업별 특허 포트폴리오 자동 분석 및<br/> 종합 분석을 통한 인사이트 제공</p>
                            </div>
                            <img src={researchall_service_detail_img_04} alt="researchall_service_detail_img_04"/>
                        </DetailServiceBox>
                    </InfoWhiteBox>
                    <Divide ref={inputForm5}/>
                    <InfoWhiteBox>
                        <DetailServiceBox>
                            <div>
                                <h1>지속적인 업데이트</h1>
                                <p>지식정보 통합 검색 (용어사전·뉴스·논문·연구보고서·R&D 과제)결과를<br/>자동 수집하고 프로젝트를 통합관리 가능</p>
                            </div>
                            <img src={researchall_service_detail_img_05} alt="researchall_service_detail_img_05"/>
                        </DetailServiceBox>
                    </InfoWhiteBox>
                    <Divide ref={inputForm6}/>
                    <InfoWhiteBox>
                        <DetailServiceBox>
                            <div>
                                <h1>최신연구정보 수집·분석</h1>
                                <p>프로젝트 문헌 (용어사전 · 뉴스 · 논문 · 연구보고서 · R&D 과제)을<br/> 일 단위로 업데이트 하여 지속적인 관리가 가능</p>
                            </div>
                            <img src={researchall_service_detail_img_06} alt="researchall_service_detail_img_06"/>
                        </DetailServiceBox>
                    </InfoWhiteBox>
                </ServiceBox>
        </>
    );
}
export default Researchall;


const IntroBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding-top: 50px;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 30vh;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 20vh;
  }
`;

const Title = styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  border-bottom: 2px solid #407BFF;
  @media ${({theme}) => theme.device.mobile} {
    font-size: 15px;
    margin-bottom: 20px;
  }
  p {
    padding-bottom: 10px;
  }
`;

const VideoBox = styled.div`
  border: 5px solid black;
  background-color: #444549;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  @media ${({theme}) => theme.device.tablet} {
    padding: 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    border: 3px solid black;
    padding: 5px;
    border-radius: 10px;
  }
  video {
    max-width: 900px;
    border: 2px solid black;
    @media ${({theme}) => theme.device.tabletL} {
      max-width: 700px;
    }
    @media ${({theme}) => theme.device.tablet} {
      max-width: 400px;
    }
    @media ${({theme}) => theme.device.mobile} {
      max-width: 270px;
      border: 1px solid black;
    }
    @media ${({theme}) => theme.device.mobileS} {
      max-width: 230px;
      border: 1px solid black;
    }
  }
`;

const Divide = styled.div`
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 50px;
`;

const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  background-color: #ffffff;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
  }
  div {
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoGreyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
  }
  div{
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


const TextBox = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.tabletL} {
    width: 50%;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: black;
      letter-spacing: -2px;
      font-size: 3.3rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.77rem;
        line-height: 1.2rem;
      }
    }

    h2 {
      color: #407BFF;
      letter-spacing: -2px;
      font-size: 3rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.75rem;
        line-height: 1.2rem;
      }
    }
  }

  p {
    text-align: center;
    font-size: 1.58rem;
    font-weight: 400;
    line-height: 2.5rem;
    color: #919191;
    margin-top: 2rem;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.4rem;
      line-height: 0.8rem;
      margin-top: 1rem;
    }
  }

  span {
    color: #407BFF;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 3rem;
    margin-top: 0.5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      margin-top: 0.5rem;
      font-size: 1.2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      line-height: 1rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      margin-top: 0.3rem;
      font-size: 0.2rem;
      line-height: 0.8rem;
    }
  }
`;
const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  @media ${({theme}) => theme.device.tablet} {
    width: 50%;
  }
img{  
  width: 100%;
}
`;

const ServiceBox = styled.div`
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tabletL} {
    padding: 30px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 50vh;
  }
`;

const TitleBox = styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  border-bottom: 2px solid #407BFF;
  @media ${({theme}) => theme.device.mobile} {
    margin-bottom: 20px;
  }
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.54rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1.5rem;
    }
  }

`;

const ServiceWrap = styled.div`
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div {
    background-color: #fff;
    padding: 30px;
    margin: 10px;
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1.5px solid #fff;
    cursor: pointer;
    //transition: all 0.8s linear;
    position: relative;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(173, 173, 173, 0.25);
    @media ${({theme}) => theme.device.tabletL} {
      width: 280px;
      height: 280px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 220px;
      height: 220px;
      margin: 5px;
      padding: 20px;
    }
    @media ${({theme}) => theme.device.mobile} {
      width: 125px;
      height: 125px;
      margin: 4px;
      padding: 6px;
    }
    @media ${({theme}) => theme.device.mobileS} {
      width: 100px;
      height: 120px;
      margin: 4px;
      padding: 6px;
      border-radius: 30px;
    }

    &:hover {
      border: 1.5px solid #407BFF;
      //opacity: .9; transition: all .2s ease;
      //border-color: #EA5413;
      box-shadow: 0 8px 15px rgba(19, 87, 234, 0.25);
      transition: all 0.3s linear;

      img {
        display: block;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }

    h1 {
      text-align: center;
      color: black;
      letter-spacing: -2px;
      font-size: 2rem;
      font-weight: 700;
      line-height: 3.5rem;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }

    span {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
      color: #919191;
      margin-top: 4rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 1rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-top: 0.8rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.5rem;
        line-height: 0.7rem;
        margin-top: 0.4rem;
      }
    }
  }
`;

const DetailServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    @media ${({theme}) => theme.device.tabletL} {
      margin-top: 0px;
    }
    // @media ${({theme}) => theme.device.tablet} {
    //   margin-top: 80px;
    // }
    h1{
      color: black;
      letter-spacing: -2px;
      font-size: 3rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2.3rem;
        font-weight: 600;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.54rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 1.5rem;
      }
    }
    p{
      text-align: center;
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;
      color: #919191;
      margin-top: 1.5rem;
      padding: 0 10px;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 1.3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 1.1rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.5rem;
        line-height: 1rem;
        margin-top: 1rem;
      }
    }
  }
  img{
    max-width: 1200px;
    width: 100%;
  }
`;
