import React from 'react';
import styled from "styled-components";
import videoUrl from "../../video/biz-ip-introduction.mp4";
import poster from "../../images/bizip_poster.png";
import bizip_img_01 from "../../images/biz-ip_img_01.png";
import bizip_img_02 from "../../images/biz-ip_img_02.png"
import bizip_img_03 from "../../images/biz-ip_img_03.png"
import bizip_service_img_01 from "../../images/bizip_service_img_01.png"
import bizip_service_img_02 from "../../images/bizip_service_img_02.png"
import bizip_service_img_03 from "../../images/bizip_service_img_03.png"

import useScrollFadeIn from '../useScrollFadeIn'

function Bizip() {

    const animatedItem = {
        0: useScrollFadeIn('up', 1, 0.1),
        1: useScrollFadeIn('up', 1, 0.1),
        2: useScrollFadeIn('up', 1, 0.1),
        3: useScrollFadeIn('up', 1, 0.1)
    };

    const mainFunction = [
        {
            title: '기업 검색',
            text: '각국의 잘 정비된 기업명칭을 바탕으로 특허를 검색할 수 있으며, 기업별 기술 포트폴리오 분석 결과 제공',
            img: bizip_service_img_01,
        },
        {
            title: '기술 검색',
            text: '특허 분류를 기준으로 기술 검색할 수 있으며, 또한 기술분분류 코드로 검색 가능',
            img: bizip_service_img_02,
        },
        {
            title: 'PETA 분석',
            text: 'PETA 시스템을 통해 최싱 메가트랜드와 인사이트 제공(주요통계 · 플레이어분석 · 기술체계분석 · 키원드분석 · 기술성장예측 · 경쟁력분석)',
            img: bizip_service_img_03,
        },
    ];


    return (
        <>
            <IntroBox>
                <Title><p>BIZ-IP</p></Title>
                <VideoBox >
                    <video src={videoUrl} poster={poster} loop controls />
                </VideoBox>
            </IntroBox>
            <Divide />
            <InfoWhiteBox>
                <div>
                    <TextBox  {...animatedItem[1]}>
                        <div>
                            <h1>전세계 특허</h1>
                            <h2>빅데이터 기반 분석</h2>
                        </div>
                        <p>
                            전세계 특허 빅데이터를 기반으로 <br/>기술혁신 동향을 분석하고<br/> 인사이트를 도출할 수 있으며, <br/>객관적이며 포괄적인 근거 기반의 분석을 지향합니다.
                        </p>
                    </TextBox>
                    <ImageBox>
                        <img src={bizip_img_01} alt="bizip_img_01"/>
                    </ImageBox>
                </div>
            </InfoWhiteBox>
            <InfoGreyBox>
                <div>
                    <ImageBox>
                        <img src={bizip_img_02} alt="bizip_img_02"/>
                    </ImageBox>
                    <TextBox  {...animatedItem[2]}>
                        <div>
                            <h1>기업의 기술 포트폴리오와</h1>
                            <h2>관련 선도기업 분석</h2>
                        </div>
                        <p>분석 대상 기업이 보유하고 있는 기술력을<br/> 포트폴리오 형태로 확인 할 수 있으며, <br/>해당 기술체계 및 관련 특허를 확인할 수 있습니다.</p>
                    </TextBox>
                </div>
            </InfoGreyBox>
            <InfoWhiteBox>
                <div>
                    <TextBox  {...animatedItem[3]}>
                        <div>
                            <h1>산업 및 기술 경쟁력 분석을 위한</h1>
                            <h2>다양한 인사이트 제공</h2>
                        </div>
                        <p>
                           유망기술분석 및 발굴을 위한 <br/>PETA 시스템을 통해 최신 메가트렌드와 <br/>인사이트를 제공합니다.
                        </p>
                    </TextBox>
                    <ImageBox>
                        <img src={bizip_img_03} alt="bizip_img_03"/>
                    </ImageBox>
                </div>
            </InfoWhiteBox>
                <ServiceBox>
                    <TitleBox>
                        <h1>주요기능</h1>
                    </TitleBox>
                    <ServiceWrap>
                        {mainFunction.map((fcn, idx) => (
                            <div key={idx}>
                                <img src={fcn.img} alt={fcn.title} />
                                <h1>{fcn.title}</h1>
                                <span>{fcn.text}</span>
                            </div>
                        ))}
                    </ServiceWrap>
                </ServiceBox>
        </>
    );
}
export default Bizip;



const IntroBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding-top: 50px;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 30vh;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 20vh;
  }
`;

const Title = styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  border-bottom: 2px solid rgb(234, 84, 19);
  @media ${({theme}) => theme.device.mobile} {
    font-size: 15px;
    margin-bottom: 20px;
  }
  p {
    padding-bottom: 10px;
  }
`;

const VideoBox = styled.div`
  border: 5px solid black;
  background-color: #444549;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  @media ${({theme}) => theme.device.tablet} {
    padding: 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    border: 3px solid black;
    padding: 5px;
    border-radius: 10px;
  }
  video {
    max-width: 900px;
    border: 2px solid black;
    @media ${({theme}) => theme.device.tabletL} {
      max-width: 700px;
    }
    @media ${({theme}) => theme.device.tablet} {
      max-width: 400px;
    }
    @media ${({theme}) => theme.device.mobile} {
      max-width: 270px;
      border: 1px solid black;
    }
    @media ${({theme}) => theme.device.mobileS} {
      max-width: 230px;
      border: 1px solid black;
    }
  }
`;

const Divide = styled.div`
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 50px;
`;

const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
  }
  div{
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoGreyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
  }

  div{
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


const TextBox = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.tabletL} {
    width: 50%;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: black;
      letter-spacing: -2px;
      font-size: 3.3rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.77rem;
        line-height: 1.2rem;
      }
    }

    h2 {
      color: #EA5413;
      letter-spacing: -2px;
      font-size: 3rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.75rem;
        line-height: 1.2rem;
      }
    }
  }

  p {
    text-align: center;
    font-size: 1.58rem;
    font-weight: 400;
    line-height: 2.5rem;
    color: #919191;
    margin-top: 2rem;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.4rem;
      line-height: 0.8rem;
      margin-top: 1rem;
    }
  }

  span {
    color: #EA5413;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 3rem;
    margin-top: 0.5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      margin-top: 0.5rem;
      font-size: 1.2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      margin-top: 0.5rem;
      font-size: 0.8rem;
      line-height: 1rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      margin-top: 0.3rem;
      font-size: 0.2rem;
      line-height: 0.8rem;
    }
  }
`;
const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  @media ${({theme}) => theme.device.tablet} {
    width: 50%;
  }
img{  
  width: 100%;
}
`;

const ServiceBox = styled.div`
    display: flex;
    flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  padding: 50px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tabletL} {
    padding: 30px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 50vh;
  }
  
`;
const TitleBox = styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  border-bottom: 2px solid rgb(234, 84, 19);
  @media ${({theme}) => theme.device.mobile} {
    margin-bottom: 20px;
  }
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2.3rem;
      font-weight: 600;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.54rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1.5rem;
    }
  }

`;

const ServiceWrap = styled.div`
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //align-items: center;


  div {
    background-color: #fff;
    padding: 30px;
    margin: 10px;
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1.5px solid #fff;
    cursor: pointer;
    //transition: all 0.8s linear;
    position: relative;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(173, 173, 173, 0.25);
    @media ${({theme}) => theme.device.tabletL} {
      width: 280px;
      height: 280px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 220px;
      height: 220px;
      margin: 5px;
      padding: 20px;
    }
    @media ${({theme}) => theme.device.mobile} {
      width: 125px;
      height: 125px;
      margin: 4px;
      padding: 6px;
    }
    &:hover {
      border: 1.5px solid #EA5413;
      //opacity: .9; transition: all .2s ease;
      //border-color: #EA5413;
      box-shadow: 0 8px 15px rgba(234, 84, 19, 0.25);
      transition: all 0.3s linear;
      img {
        display: block;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }

    h1 {
      text-align: center;
      color: black;
      letter-spacing: -2px;
      font-size: 2rem;
      font-weight: 700;
      line-height: 3.5rem;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 3rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.8rem;
        line-height: 1rem;
      }
    }

    span {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
      color: #919191;
      margin-top: 4rem;
      text-align: center;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 1rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1rem;
        line-height: 1.4rem;
        margin-top: 0.8rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.5rem;
        line-height: 0.7rem;
        margin-top: 0.4rem;
      }
    }
  }
`;

