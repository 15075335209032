import React, {useState} from 'react';
import styled from "styled-components";
import { useNavigate,useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import ServiceBanner from "../components/Services/ServiceBanner";
import Contents from "../components/Services/Contents";
function Services() {
    const navigate = useNavigate();

    // url에서 쿼리 가져와서 변수 만들기
    let sch = useLocation().search;
    let query = new URLSearchParams(sch);
    let category = query.get('category');

    const basicURL = `/services`;

    const items = [
        {
            type: 'researchall',
            title: 'ReasearchAll'
        },
        {
            type: 'bizip',
            title: 'BIZ-IP'
        },
        {
            type: 'aivoucher',
            title: 'AI 바우처(공급기관)'
        },
        {
            type: 'datavoucher',
            title: '데이터 바우처(공급기관)'
        }
    ];
    const [select, setSelect] = useState(category);

    const onClick = (type) => {
        setSelect(type);
        navigate(`${basicURL}${'?category='}${type}`);
    };



    return (
        <>
            <Header />
            <ServiceBanner category={category}/>
            <SubMenu>
                <ul>
                    {items.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => onClick(item.type)}
                            className={`${select === item.type ? 'select' : ''}`}
                        >
                            {item.title}
                        </li>
                    ))}
                </ul>
            </SubMenu>
            <Contents category={category}/>
            <Footer />
        </>
    );
}
export default Services;


const SubMenu = styled.section`
  display: flex;
  justify-content: center;
  font-weight: 500;
  background-color: rgb(3, 9, 21);
  color: #fff;
  ul{
    max-width: 1200px;
    display: flex;
    justify-content: center;
    background-color: rgb(3, 9, 21);
    li{
      min-width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 60px;
      color: #fff;
      cursor: pointer;
      @media ${({theme}) => theme.device.tabletL} {
        min-width: 180px;
        padding: 30px 20px;
        font-size: 15px;
      }
      @media ${({theme}) => theme.device.tablet} {
        min-width: 90px;
        padding: 20px 8px;
        font-size: 12px;
      }
      @media ${({theme}) => theme.device.mobile} {
        min-width: 70px;
        padding: 20px 4px;
        font-size: 11px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        min-width: 50px;
        padding: 14px 2px;
        font-size: 8px;
      }
    }
    .select {
      background-color: #fff;
      color:  rgb(3, 9, 21);
    }
  }
  ul li:hover {
    color: rgb(234, 84, 19);
  }
`;


