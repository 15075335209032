import React from 'react';
import styled from "styled-components";
import youtube_ico from "../images/youtube.png"
import blog_ico from "../images/blog.png"
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";

function Footer() {
    const navigate = useNavigate();
    const goHome = () => navigate("/");

    // 패밀리사이트 이동 Url 변수
    const goResearchall = "https://researchall.net"
    const goBizIp = "https://biz-ip.com/"
    const GoExpertPlatform = "https://expert-platform.techdna.kr/"

    // sns 이동 Url 변수
    const goBlog = "https://blog.naver.com/techdna"
    const goYoutube = "https://www.youtube.com/@techdna2502"

    return (
        <FooterBox>
            <FtCon>
                <FtTop>
                    <FtMenu>
                        <li onClick={goHome}>HOME</li>
                        <li onClick={() => {
                            navigate(`${'/services?category=researchall'}`)
                        }}>SERVICES
                        </li>
                        <li onClick={() => {
                            navigate(`${'/aboutus?category=vision'}`);
                        }}>ABOUT US
                        </li>
                    </FtMenu>
                    <FtBtn>
                        <button>
                            <span>Family Site 바로가기</span>
                            <FontAwesomeIcon icon={faCaretDown}/>
                        </button>
                        <ul>
                            <li onClick={() => {
                                window.open(goResearchall)
                            }}>ResearchALL
                            </li>
                            <li onClick={() => {
                                window.open(goBizIp)
                            }}>BIZ-IP
                            </li>
                            {/*<li onClick={() => {*/}
                            {/*    window.open(GoExpertPlatform)*/}
                            {/*}}>expert-platform*/}
                            {/*</li>*/}
                        </ul>
                    </FtBtn>
                </FtTop>
                <FtBot>
                    <FtInfo>
                        본사 : 경기도 하남시 미사대로 550 ㅣ
                        현대지식산업센터 한강미사 1차 A-420호 ㅣ
                        사업자번호 : 132-87-01103 ㅣ
                        대표자 : 배진우 <br/>
                        E-mail : help@techdna.kr ㅣ
                        Tel : 031-8027-3753 ㅣ
                        Fax : 02-6008-9580 <br/>
                        <span>
                            Copyright © 2023 TechDNA | Powered by TECHDNA
                        </span>
                    </FtInfo>
                    <SnsBox>
                        <img onClick={() => {
                            window.open(goBlog)
                        }} src={blog_ico} alt="blog_icon"/>
                        <img onClick={() => {
                            window.open(goYoutube)
                        }} src={youtube_ico} alt="youtube_icon"/>
                    </SnsBox>
                </FtBot>
            </FtCon>
        </FooterBox>
    );
}

export default Footer;

const FooterBox = styled.div`
  width: 100%;
  background-color: #030915;
  letter-spacing: 1px;
`;

const FtCon = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FtTop = styled.div`
  padding: 60px 0 35px;
  border-bottom: 1px solid rgba(255, 255, 255, .35);
  display: flex;
  justify-content: space-between;
  @media ${({theme}) => theme.device.mobile} {
    padding: 30px 0 25px;
    flex-direction: column;
  }
`;

const FtMenu = styled.ul`
  font-size: 0.9em;
  font-weight: ${({theme}) => theme.fontWeight.light};
  display: flex;
  color: #fff;
  @media ${({theme}) => theme.device.mobile} {
    font-size: 0.8em;
  }

  li {
    line-height: 28px;
    cursor: pointer;

    &::after {
      content: "";
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #FFF;
      border-radius: 4px;
      vertical-align: middle;
      margin: 0 14px 2px;
      @media ${({theme}) => theme.device.mobile} {
        width: 3px;
        height: 3px;
        margin: 0 10px 2px;
      }
    }

    :last-child:after {
      display: none;
    }
  }
`;

const FtBtn = styled.div`
  button {
    width: 200px;
    background-color: rgb(234, 84, 19);
    color: white;
    padding: 10px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    @media ${({theme}) => theme.device.tablet} {
      width: 150px;
      padding: 8px;
      font-size: 12px;
    }
    @media ${({theme}) => theme.device.mobile} {
      margin-top: 5px;
    }

    span {
      width: 200px;
    }
  }

  ul {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    li {
      font-size: 14px;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      @media ${({theme}) => theme.device.tablet} {
        font-size: 13px;
      }
    }
  }

  ul li:hover {
    background-color: #ddd;
    color: #EA5413;
  }

  :hover ul {
    display: block;
  }

  :hover button {
    background-color: rgb(234, 84, 19);
  }
`;

const FtBot = styled.div`
  padding: 35px 0 60px;
  display: flex;
  justify-content: space-between;
  @media ${({theme}) => theme.device.mobile} {
    padding: 28px 0 30px;
  }
`;

const FtInfo = styled.div`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 24px;
  text-align: left;
  word-break: keep-all;
  font-weight: 300;
  @media ${({theme}) => theme.device.tabletL} {
    max-width: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    max-width: 380px;
    font-size: 12px;
    line-height: 20px;
  }
  @media ${({theme}) => theme.device.mobile} {
    max-width: 200px;
    font-size: 11px;
    line-height: 17px;
  }
  @media ${({theme}) => theme.device.mobileS} {
    font-size: 10px;
    line-height: 15px;
  }

  span {
    color: rgba(255, 255, 255, 0.3);
    font-weight: 300;
    display: block;
    margin-top: 10px;
  }
`;

const SnsBox = styled.div`
  min-width: 80px;
  img {
    width: 45px;
    margin-left: 10px;
    cursor: pointer;
    @media ${({theme}) => theme.device.tablet} {
      width: 26px;
      margin-left: 8px;
    }
  }
`;
