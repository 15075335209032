import React from 'react';
import styled from "styled-components";
import Researchall from "./Researchall"
import Bizip from "./Bizip";
import AiVoucher from "./AiVoucher";
import DataVoucher from "./DataVoucher";
function Contents(props) {
    const {category} = props;

    return (
            <Container>
                {category === 'researchall' && <Researchall />}
                {category === 'bizip' && <Bizip />}
                {category === 'aivoucher' && <AiVoucher />}
                {category === 'datavoucher' && <DataVoucher />}
            </Container>
    );
}
export default Contents;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
