import React from 'react';
import styled from "styled-components";
import ex_img_01 from "../../images/ex_img_01.png";
import ex_img_02 from "../../images/ex_img_02.png";
import ex_img_03 from "../../images/ex_img_03.png";
import ex_img_04 from "../../images/ex_img_04.png";

import aivoucher_img_01 from "../../images/aivoucher_img_01.png";
// import useScrollFadeIn from "../useScrollFadeIn";

function AiVoucher() {

    // const animatedItem = {
    //     0: useScrollFadeIn('up', 1, 0.1),
    //     1: useScrollFadeIn('up', 1, 0.1),
    //     2: useScrollFadeIn('up', 1, 0.1),
    //     3: useScrollFadeIn('up', 1, 0.1),
    // };

    return (
        <>
            <IntroBox>
                <Title><p>AI 바우처(공급기관)</p></Title>
                <h1>특허 빅데이터 기반의 AI솔루션 개발 및 서비스</h1>
                <span>㈜테크디엔에이는 전세계 특허데이터를 보유한 <br/>빅데이터 분석 전문기업으로 특허가 보유한 기업(출원인)정보, <br/>전문가 개인(발명자)정보를 활용한 AI기반 솔루션을 제공</span>
            </IntroBox>
            <Divide />
            <InfoWhiteBox>
                    <InfoBox>
                            <h1>활용 정보의 범위</h1>
                        <TableFill>
                            <thead></thead>
                            <tbody className="table-hover">
                            <tr>
                                <td className="text-left">정보구분</td>
                                <td className="text-left">의미 및 활용방안</td>
                            </tr>
                            <tr>
                                <td className="text-left">발명의 명칭,요약, 청구항</td>
                                <td className="text-left">기술분야 파악,키워드 도출</td>
                            </tr>
                            <tr>
                                <td className="text-left">출원인</td>
                                <td className="text-left">기업정보,개인전문가</td>
                            </tr>
                            <tr>
                                <td className="text-left">발명자</td>
                                <td className="text-left">기술분야병 개인전문가 정보</td>
                            </tr>
                            <tr>
                                <td className="text-left">IPC/CPC code</td>
                                <td className="text-left">기술분류 라벨링,키워드 도출</td>
                            </tr>
                            <tr>
                                <td className="text-left">출원인 국적</td>
                                <td className="text-left">국가별 현황 및 경쟁력</td>
                            </tr>
                            <tr>
                                <td className="text-left">피인용</td>
                                <td className="text-left">기술간,출원인간 연관도,관계</td>
                            </tr>
                            <tr>
                                <td className="text-left">패밀리정보</td>
                                <td className="text-left">기술의 질적 수준,해외진출/사업화의지 등</td>
                            </tr>
                            </tbody>
                        </TableFill>
                    </InfoBox>
            </InfoWhiteBox>
            <InfoGreyBox>
                <InfoBox>
                    <h1>활용예시</h1>
                    <ExBox>
                        <div>
                            <ImgBox><img src={ex_img_01} alt="ex_img_01"/></ImgBox>
                            <h1>특정기술분야의 <br/>기업 도출</h1>
                        </div>
                        <div>
                            <ImgBox><img src={ex_img_02} alt="ex_img_02"/></ImgBox>
                            <h1>기업과 전문가의 <br/>AI기반 매칭 시도</h1>
                        </div>
                        <div>
                            <ImgBox><img src={ex_img_03} alt="ex_img_03"/></ImgBox>
                            <h1>AI기반 빅데이터 분석을 <br/>통한 트랜드 분석</h1>
                        </div>
                        <div>
                            <ImgBox><img src={ex_img_04} alt="ex_img_04"/></ImgBox>
                            <h1>이슈 자동 도출</h1>
                        </div>
                    </ExBox>
                </InfoBox>
            </InfoGreyBox>
            <InfoWhiteBox>
                {/*<div>*/}
                {/*    <ImageBox>*/}
                {/*        <img src={aivoucher_img_01} alt="aivoucher_img_01"/>*/}
                {/*    </ImageBox>*/}
                {/*    <TextBox>*/}
                {/*        <div>*/}
                {/*            <h1>기술 특화 마이닝 엔진 구축</h1>*/}
                {/*        </div>*/}
                {/*        <p>– LDA 기반의 토픽 추출(자연어 처리) 결과와 기술용어사전 매칭을 통해 기존 자연어처리의 문제점 해결 및 매칭 정확도 개선</p>*/}
                {/*        <p>– 특허정보 특화 마이닝 기술 개발을 위한 비기술 용어 절단자(Stop-Word) DB 구축</p>*/}
                {/*        <span>*특허청에서 제공하는 ‘기술분야별 기술용어사전’ 활용 예정</span>*/}
                {/*        <p>– 기술분류코드(선진 특허분류*)를 기반으로 기업 및 전문가 기술 분류코드 할당</p>*/}
                {/*        <span>* 선진 특허분류코드(CPC Code) : 국제특허분류(IPC)에서 개선된 특허 분류코드로 약 27만개의 세부분류로 구성, 단순 기술뿐만 아니라 제품-모듈-기술-세부기술까지 상세하게 표현이 가능하고, 최신 기술을 반영하여 개정되고 있으며, 업데이트를 통해 다양한 최신 기술을 표현할 수 있는 분류코드임</span>*/}
                {/*    </TextBox>*/}
                {/*</div>*/}
                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>기술 특화 마이닝 엔진 구축</h1>
                        </div>
                            <p>LDA 기반의 토픽 추출(자연어 처리)결과와 기술용어사전 매칭을 통해 기존 자연어처리의 문제점 해결</p>
                            <p>특허정보 특화 마이닝 기술 개발을 위한 비기술 용어 절단자(Stop-Word) DB 구축</p>
                            <span>*특허청에서 제공하는 ‘기술분야별 기술용어사전’ 활용 예정</span>
                            <p>기술분류코드(선진 특허분류*)를 기반으로 기업 및 전문가 기술 분류코드 할당</p>
                            <span>* 선진 특허분류코드(CPC Code) : 국제특허분류(IPC)에서 개선된 특허 분류코드로<br/> 약 27만개의 세부분류로 구성,단순 기술뿐만 아니라 제품-모듈-기술-세부기술까지<br/> 상세하게 표현이 가능하고, 최신 기술을 반영하여 개정되고 있으며, <br/>업데이트를 통해 다양한 최신 기술을 표현할 수 있는 분류코드임</span>
                    </TextBox>
                    <ImageBox>
                        <img src={aivoucher_img_01} alt="aivoucher_img_01"/>
                    </ImageBox>
                </InfoBox>
            </InfoWhiteBox>
        </>
    );
}
export default AiVoucher;

const IntroBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  padding-top: 50px;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 30vh;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 20vh;
  }
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  span{
    padding: 0 10px;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #919191;
    margin-top: 2rem;
    max-width: 1200px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 1rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  border-bottom: 2px solid rgb(234, 84, 19);
  @media ${({theme}) => theme.device.mobile} {
    font-size: 15px;
    margin-bottom: 20px;
  }
  p {
    padding-bottom: 10px;
  }
`;

const Divide = styled.div`
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 50px;
`;

const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 100px 10px;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
    padding: 50px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // min-height: 65vh;
  // padding: 50px 10px;
  // @media ${({theme}) => theme.device.tablet} {
  //   min-height: 20vh;
  // }
  div{
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoGreyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  padding: 50px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tabletL} {
    min-height: 40vh;
  }
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
  }
  div{
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


const InfoBox = styled.div`
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // h1{
  //   color: black;
  //   letter-spacing: -2px;
  //   font-size: 3rem;
  //   font-weight: 700;
  //   line-height: 5rem;
  //   text-align: center;
  //   @media ${({theme}) => theme.device.tabletL} {
  //     font-size: 2rem;
  //     line-height: 3rem;
  //   }
  //   @media ${({theme}) => theme.device.tablet} {
  //     font-size: 1.5rem;
  //     line-height: 2rem;
  //   }
  //   @media ${({theme}) => theme.device.mobile} {
  //     font-size: 1rem;
  //     line-height: 1.2rem;
  //   }
  // }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  //img {
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //  max-width: 800px;
  //  width: 50%;
  //}
`
const TableFill = styled.table`
  background: white;
  border-radius:3px;
  border-collapse: collapse;
  max-height: 320px;
  margin: 20px auto auto;
  max-width: 1000px;
  padding:5px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
  tbody {
    tr{
      border-top: 1px solid #C1C3D1;
      border-bottom-: 1px solid #C1C3D1;
      color:#666B85;
      font-size:16px;
      font-weight:normal;
      text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
      :hover td {
        background:#4E5066;
        color:#FFFFFF;
        border-top: 1px solid #22262e;
      }
      :first-child {
        border-top:none;
      }
      :last-child {
        border-bottom:none;
      }
      :nth-child(odd) td {
        background:#EBEBEB;
      }
      :nth-child(odd):hover td {
        background:#4E5066;
      }
      td{
        background:#FFFFFF;
        padding:20px;
        text-align:left;
        vertical-align:middle;
        font-weight:300;
        font-size:18px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
        border-right: 1px solid #C1C3D1;
        cursor: pointer;
        :last-child {
          border-right: 0px;
        }
        @media ${({theme}) => theme.device.tabletL} {
          padding:15px;
        }
        @media ${({theme}) => theme.device.tablet} {
          padding:12px;
          font-size:15px;
        }
        @media ${({theme}) => theme.device.tablet} {
          padding:8px;
          font-size:11px;
        }
      }
    }
  }
`;


const ExBox = styled.div`
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    div {
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
        @media ${({theme}) => theme.device.tabletL} {
        margin: 10px 5px;
      }
      @media ${({theme}) => theme.device.mobile} {
        margin: 5px 2px;
      }
      h1 {
        text-align: center;
        color: black;
        letter-spacing: -2px;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.6rem;
        width: 200px;
        height: 90px;
          @media ${({theme}) => theme.device.tabletL} {
            width: 150px;
            font-size: 1rem;
            line-height: 1.3rem;
            height: 40px;
        }
        @media ${({theme}) => theme.device.tablet} {
          width: 80px;
          font-size: 0.65rem;
          line-height: 0.9rem;
          height: 30px;
        }
        @media ${({theme}) => theme.device.mobile} {
          width: 55px;
          font-size: 0.6rem;
          line-height: 0.8rem;
          height: 38px;
        }
    }
      
  }
`;

const ImgBox = styled.div`
  margin: 30px 30px 0px 30px;
   border-radius: 50%;
   border: 1.5px solid #fff;
   background-color: #fff;
   width: 200px;
   height: 200px;
  box-shadow: 0 8px 15px rgba(173, 173, 173, 0.25);
  @media ${({theme}) => theme.device.tabletL} {
    width: 150px;
    height: 150px;
    margin: 10px 10px 0px 10px;
  }
  @media ${({theme}) => theme.device.tablet} {
    width: 80px;
    height: 80px;
    margin: 5px 5px 0px 5px;
  }
  @media ${({theme}) => theme.device.mobile} {
    width: 55px;
    height: 55px;
  }
  img {
    width: 50%;
    height: 50%;
  }
`;

const TextBox = styled.div`
    padding: 0 20px;
  //width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.tabletL} {
    //width: 90%;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      color: black;
      letter-spacing: -2px;
      font-size: 4rem;
      font-weight: 700;
      line-height: 5rem;
      text-align: center;
      margin-bottom: 4rem;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 2rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  p {
    ::before {
      content: '▶ ';
    }
    padding-bottom: 5px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    color: #919191;
    width: 100%;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.05rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }

  span {
    padding-left: 22px;
    color: #EA5413;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 0.5rem;
      line-height: 1.2rem;
      padding-left: 4px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.3rem;
      line-height: 0.7rem;
    }
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 900px;
    margin: 50px;
    @media ${({theme}) => theme.device.tabletL} {
      width: 650px;
      margin: 20px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 100%;
      margin: 10px 0;
    }
  }
`;
