import React from 'react';
import styled from "styled-components";

function History() {
    return (
        <InfoWhiteBox>
            <ul>
                <li className="left">
                    <HisDoLeft></HisDoLeft>
                    <HisTxtLeft>
                        <h1>2023</h1>
                        <ul>
                            <li><p><b></b> ResearchALL 서비스 출시</p></li>
                            <li><p><b></b> 대한무역투자진흥공사 "수출바우처" 공급기관 선정</p></li>
                        </ul>
                    </HisTxtLeft>
                </li>

                <li className="right">
                    <HisDoRight></HisDoRight>
                    <HisTxtRight>
                        <h1>2022</h1>
                        <ul className="his_list">
                            <li><p><b></b> 한국산업기술진흥원 “글로벌 협력대상기업 발굴을 위한 특허 데이터 분석” 수행</p></li>
                            <li><p><b></b> 한국과학기술기획평가원 “주요국 협력 아젠다 발굴을 위한 특허 수준 분석 조사” 수행</p></li>
                            <li><p><b></b> 특허-기업정보 기반 신사업 발굴을 위한 MOU 체결(NICE평가정보(주))</p></li>
                            <li><p><b></b> 한국과학기술기획평가원 “바이오, 디스플레이분야 소부장 미래선도품목 특허 및 논문 분석” 수행</p></li>
                            <li><p><b></b> (재)경기테크노파크 “기술닥터 인공지능 매칭 시스템 유지관리” 수행</p></li>
                            <li><p><b></b> 한국지식재산위원회 “국내외 바이오-IP 동향 분석 연구 및 이슈페이퍼 정기 발행” 수행</p></li>
                            <li><p><b></b> 한국기술일자리진흥원 “연구산업육성 미래연구산업서비스” 수행</p></li>
                            <li><p><b></b> 재)한국특허정보원 “지식재산서비스 성장지원 사업” 수행</p></li>
                            <li><p><b></b> 기술-기업 정보 융합을 통한 개방혁신 플랫폼(가칭:TD-IPMS) 시범서비스 운영</p></li>
                            <li><p><b></b> 한국데이터산업진흥원 “2022년 데이터바우처 지원사업” 공급기관 선정/수행</p></li>
                            <li><p><b></b> 정보통신산업진흥원 “AI바우처” 공급기관 선정</p></li>
                        </ul>
                    </HisTxtRight>
                </li>
                <li className="left">
                    <HisDoLeft></HisDoLeft>
                    <HisTxtLeft>
                        <h1>2021</h1>
                        <ul>
                            <li><p><b></b> NST, UNIST 대상 기술이전 지원 시스템 개발/운영</p></li>
                            <li><p><b></b> 한국산업기술진흥원 “특허 기반의 수소산업 및 AI분야 글로벌 협력기업 DB구축” 수행</p></li>
                            <li><p><b></b> 한국발명진흥회 “2021년 기업 기술애로과제 해결을 위한 맞춤형 전문가 활용 방안” 수행</p></li>
                            <li><p><b></b> 한국과학기술기획평가원 “감염병 위기대응 핵심기술 관련 특허분석” 수행</p></li>
                            <li><p><b></b> 한국특허전략개발원 “2021년 유망 R&amp;D 분야 발굴 방법론 연구” 수행</p></li>
                            <li><p><b></b> 국가지식재산위원회 “국내외 바이오-IP 동향 분석 및 이슈페이퍼 정기 발행” 수행</p></li>
                            <li><p><b></b> 한국특허전략개발원 “국내외 특허 빅데이터 관련 정보 수집 분석” 수행</p></li>
                            <li><p><b></b> 한국특허전략개발원 “특허 메가트랜드 포털 고도화 용역” 수행</p></li>
                            <li><p><b></b> 한국과학기술기획평가원 “소부장 미래선도품목 특허 및 논문 분석” 수행</p></li>
                            <li><p><b></b> 한국과학기술기획평가원 “정부 데이터 산업 관련 현황 분석 및 유형 체계화 연구” 수행</p></li>
                            <li><p><b></b> 한국특허전략개발원 “경쟁력 측정을 위한 특허분석 및 유효특허 구축 방법론 연구” 수행</p></li>
                            <li><p><b></b> 과학기술일자리진흥원 “2021년 대형사업단 성과활용지원사업” 수행</p></li>
                            <li><p><b></b> 한국산업기술진흥원 “DX한걸음 프로젝트 자문” 수행</p></li>
                            <li><p><b></b>본사 이전(대전-하남)</p></li>
                        </ul>
                    </HisTxtLeft>
                </li>

                <li className="right">
                    <HisDoRight></HisDoRight>
                    <HisTxtRight>
                        <h1>2020</h1>
                        <ul className="his_list">
                            <li><p><b></b> 사단법인 한국플렉시블일렉트로닉스산업협회 “차세대 하이브리드 PCB 기술 특허동향조사 ” 선정</p></li>
                            <li><p><b></b> 한국특허전략개발원 “국내외 특허 지표 및 분석 사례 현황 조사” 선정</p></li>
                            <li><p><b></b> 한국산업기술진흥원 “코로나19시대 유망 신산업 창출전략 수립을 위한 특허분석” 선정</p></li>
                            <li><p><b></b> 한국특허전략개발원 “해외특허성과 정비 및 검증 가이드 제작” 선정</p></li>
                            <li><p><b></b> 경기도경제과학진흥원 “2020년 경기도 인공지능(AI) 실증 사업” 선정</p></li>
                            <li><p><b></b> 한국특허전략개발원 “2020년 특허 빅데이터 플랫폼 고도화” 선정</p></li>
                            <li><p><b></b> 한국데이터산업진흥원 “2020년 데이터바우처 지원사업” 선정</p></li>
                            <li><p><b></b> 식품의약품안전처 “2020년 의약품 해외 특허정보 조사분석” 선정</p></li>
                            <li><p><b></b> 한국과학기술기획평가원 “특허분석을 통한 소재 핵심기술 개발동향과 글로벌 트렌드 분석” 선정</p></li>
                            <li><p><b></b> 글로벌 서비스를 위한 Biz-IP 영문 서비스 구축</p></li>
                            <li><p><b></b> 한국특허전략개발원 “특허분석을 통한 소재 핵심기술 분석 지표 및 동향 분석” 선정</p></li>
                        </ul>
                    </HisTxtRight>
                </li>
                <li className="left">
                    <HisDoLeft></HisDoLeft>
                    <HisTxtLeft>
                        <h1>2019</h1>
                        <ul>
                            <li><p><b></b> 국가과학기술연구회 “출연(연) 보유특허 조사, 분석, 관리체계 구축 및 현안기술분야 활용전략 수립” 선정</p></li>
                            <li><p><b></b> 한국특허전략개발원 “정부 R&amp;D 소재부품장비 관련 R&amp;D 과제 현황 조사” 수행</p></li>
                            <li><p><b></b> 광주과학기술원 “CPC 코드 기반의 광주과기원 보유특허 기술분류” 수행</p></li>
                            <li><p><b></b> 산업연구원 “특허 빅데이터 분석을 통한 산업 생태계 경쟁력 분석” 수행</p></li>
                            <li><p><b></b> 기업부설연구소 설립 – ㈜테크디엔에이 개방혁신전략연구원</p></li>
                            <li><p><b></b> KIST “소재부품 기반 기술 국산화를 위한 KIST 기술사업화 융합 전략맵 구축” 수행</p></li>
                            <li><p><b></b> 한국데이터산업진흥원 “2019 데이터바우처 지원사업” 공급기업 선정(특허 빅데이터 분야)</p></li>
                            <li><p><b></b> 한국산업기술진흥원 “산업생태계 변화 예측을 통한 유망업종 발굴” 수행</p></li>
                            <li><p><b></b> 한국특허전략개발원 “특허 빅데이터 기반 산업 혁신전략 수립” 수행</p></li>
                            <li><p><b></b> 한국특허전략개발원 “산업 로드맵 수립을 위한 특허 분석” 수행</p></li>
                            <li><p><b></b> 특허분류체계별 주요 키워드 DB 및 시계열 Tech-Sensing 시스템 구축</p></li>
                            <li><p><b></b> 기술분야별 전문가(발명자) 및 지역특화 출원인 분석 DB 구축 완료</p></li>
                            <li><p><b></b> 특허분류체계(CPC, 26만개)별 주요 출원인 출원활동 분석 DB 구축</p></li>
                        </ul>
                    </HisTxtLeft>
                </li>

                <li className="right">
                    <HisDoRight></HisDoRight>
                    <HisTxtRight>
                        <h1>2018</h1>
                        <ul className="his_list">
                            <li><p><b></b> NICE 평가정보 업무 협약, 국내외 기술력 보유 기업 추천 및 분석 업무</p></li>
                            <li><p><b></b> ㈜테크디엔에이 기업부설연구소 설립 (서울시 강남구)</p></li>
                            <li><p><b></b> 출원인 명칭, 국적 정비 DB 구축 (출원인명칭 전세계 376만개 기업)</p></li>
                            <li><p><b></b> KOTRA, “기업 기술 포트폴리오 기반의 수요기업 발굴” 프로젝트 수행</p></li>
                            <li><p><b></b> ㈜테크디엔에이 법인 설립 (충남 대전)</p></li>
                            <li><p><b></b> 기술/기업 포트폴리오 탐색 서비스 개시(Biz-IP) – 특허분류체계/주요 출원인 검색, 기업별 특허포트폴리오 검색 시스템</p></li>
                            <li><p><b></b> 특허/기업정보 연계 DB 구축 완료</p></li>
                            <li><p><b></b> 특허정보활용 창업경진대회 최우수상(특허청장상, 전세계 특허 DB 확보)</p></li>
                        </ul>
                    </HisTxtRight>
                </li>
            </ul>
        </InfoWhiteBox>
    );
}

export default History;

const InfoWhiteBox = styled.div`
  padding: 100px 20px 70px;
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 2;
  @media ${({theme}) => theme.device.mobile} {
    width: 300px;
    padding: 100px 0px 70px;
  }
  @media ${({theme}) => theme.device.mobileS} {
    width: 270px;
  }
  ::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }

  ul {
    width: 100%;
    position: relative;
    text-align: right;
    z-index: 1;
    .left {
      margin-left: 9px;
      width: 50%;
      display: flex;
      flex-direction: row-reverse;
      text-align: right;
      align-items: flex-start;
      @media ${({theme}) => theme.device.mobile} {

        margin-left: 0px;
        width: 100%;

        text-align: left;
        display: flex;
        flex-direction: row;
      }
    }

    .right {
      justify-content: flex-start;
      text-align: left;
      margin-left: calc(50% - 9px);
      flex-direction: row;
      @media ${({theme}) => theme.device.mobile} {
        margin-left: 0px;
        width: 100%;
      }
    }
  }
`;


const HisDoLeft = styled.div`
  display: flex;
  align-items: center;

  ::before {
    content: '';
    display: block;
    width: 90px;
    height: 1px;
    background: #0a2689;
    @media ${({theme}) => theme.device.tabletL} {
      width: 70px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 50px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;

  ::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 3px solid #0a2689;
    border-radius: 50%;
    @media ${({theme}) => theme.device.tabletL} {
      width: 15px;
      height: 15px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 13px;
      height: 13px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;
`;
const HisDoRight = styled.div`
  display: flex;
  align-items: center;

  ::after {
    content: '';
    display: block;
    width: 90px;
    height: 1px;
    background: #0a2689;
    @media ${({theme}) => theme.device.tabletL} {
      width: 70px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 50px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;

  ::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 3px solid #0a2689;
    border-radius: 50%;
    @media ${({theme}) => theme.device.tabletL} {
      width: 15px;
      height: 15px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 13px;
      height: 13px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;
`;

const HisTxtLeft = styled.div`
  margin-right: 30px;
  height: 100%;
  position: relative;
  top: -16px;
  @media ${({theme}) => theme.device.tabletL} {
    margin-right: 20px;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin-right: 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    top: -36px;
    justify-content: flex-start;
    text-align: left;
    margin-left: 10px;
    flex-direction: row;
  }
  h1 {
    font-size: 40px;
    font-weight: 600;
    color: #0a2689;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 35px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 30px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-weight: 600;
      color: #0a2689;
      font-family: 'Montserrat';
      margin-bottom: 20px;
    }
  }
;

  ul {
    @media ${({theme}) => theme.device.mobile} {
      text-align: left;
    }
    li {
      //margin-bottom: 10px;
      margin: 0 0 10px 0;
      position: relative;
      padding-right: 15px;
      width: 100%;
      @media ${({theme}) => theme.device.mobile} {
        padding-left: 15px;
        padding-right: 0px;
      }
      ::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #6677fa;
        position: absolute;
        right: 0;
        top: 12px;
        @media ${({theme}) => theme.device.mobile} {
          left: 0;
        }
      }

      p {
        font-size: 15px;
        letter-spacing: -1px;
        font-weight: 400;
        color: #333;
        line-height: 28px;
        font-family: 'NanumSquare', sans-serif;
        word-break: keep-all;
        @media ${({theme}) => theme.device.tablet} {
          font-size: 14px;
          line-height: 26px;
        }
        b {
          font-weight: 700;
          color: #000;
        }
      }
    }
  }
`;

const HisTxtRight = styled.div`
  height: 100%;
  position: relative;
  top: -36px;

  justify-content: flex-start;
  text-align: left;
  margin-left: 150px;
  flex-direction: row;
  @media ${({theme}) => theme.device.tabletL} {
    margin-left: 110px;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin-left: 80px;
  }
  @media ${({theme}) => theme.device.mobile} {
    margin-left: 10px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    color: #0a2689;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 35px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 30px;
    }
  }
;

  ul {
    li {
      margin: 0 0 10px 0;
      position: relative;
      padding-left: 15px;
      width: 100%;

      ::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #6677fa;
        position: absolute;
        left: 0;
        top: 12px;
      }

      p {
        font-size: 15px;
        letter-spacing: -1px;
        font-weight: 400;
        color: #333;
        line-height: 28px;
        font-family: 'NanumSquare', sans-serif;
        word-break: keep-all;

        justify-content: flex-start;
        text-align: left;
        margin-left: calc(0%);
        flex-direction: row;
        @media ${({theme}) => theme.device.tablet} {
          font-size: 14px;
          line-height: 26px;
        }
        b {
          font-weight: 700;
          color: #000;
        }
      }
    }
  }
`;
