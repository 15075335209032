import React from 'react';
import styled from "styled-components";
import datavoucher_img_01 from "../../images/datavoucher_img_01.png";
import datavoucher_img_02 from "../../images/datavoucher_img_02.png";
import datavoucher_img_03 from "../../images/datavoucher_img_03.png";
import datavoucher_img_04 from "../../images/datavoucher_img_04.png";

// import useScrollFadeIn from "../useScrollFadeIn";
function DataVoucher() {
    // const animatedItem = {
    //     0: useScrollFadeIn('up', 1, 0.1),
    //     1: useScrollFadeIn('up', 1, 0.1),
    //     2: useScrollFadeIn('up', 1, 0.1),
    //     3: useScrollFadeIn('up', 1, 0.1),
    //     // 4: useScrollFadeIn('left', 1, 0.2),
    //     // 5: useScrollFadeIn('right', 1, 0.3),
    //     // 6: useScrollFadeIn('left', 1, 0.3),
    // };
    return (
        <>
            <IntroBox>
                <Title><p>DATA 바우처(공급기관)</p></Title>
                <h1>데이터 바우처 지원 사업이란?</h1>
                <span>데이터 기반 비즈니스 혁신 및 신규 제품, <br/>서비스 개발 등이 필요한 기업이 전문 기업의 데이터 구매 및 <br/>가공 서비스를 바우처 형식으로 지원 받는 사업입니다.</span>
            </IntroBox>
            <Divide />
            <InfoWhiteBox>
                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>데이터 바우처 사업 구조</h1>
                        </div>
                    </TextBox>
                    <ImageBox>
                        <img src={datavoucher_img_01} alt="datavoucher_img_01"/>
                    </ImageBox>
                </InfoBox>
            </InfoWhiteBox>
            <InfoGreyBox>
                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>데이터 활용 및 가공 예시</h1>
                        </div>
                    </TextBox>
                    <ImageBox>
                        <img src={datavoucher_img_02} alt="datavoucher_img_02"/>
                    </ImageBox>
                </InfoBox>
            </InfoGreyBox>
            <InfoWhiteBox>
                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>데이터 바우처 사업 <br/>진행 프로세스</h1>
                        </div>
                    </TextBox>
                    <ImageBox>
                        <img src={datavoucher_img_03} alt="datavoucher_img_03"/>
                    </ImageBox>
                </InfoBox>
            </InfoWhiteBox>

            <InfoGreyBox>

                <InfoBox>
                    <TextBox>
                        <div>
                            <h1>2023년 데이터 바우처</h1>
                            <h2>기업이 원하는 데이터, <br/>정부 지원 받아 시작해 보세요!</h2>
                        </div>
                        <TextBoxInfo>
                            <p>일정</p>
                            <ul>
                                <li>구매, 일반가공: 2023.02.24(금) ~ 2023.03.28(화)</li>
                                <li>AI 가공: 2023.02.24(금) ~ 2023.03.29(수)</li>
                            </ul>
                            <p>정부 지원범위(23년 개정)</p>
                        </TextBoxInfo>
                    </TextBox>
                    <ImageBox>
                        <img src={datavoucher_img_04} alt="datavoucher_img_04"/>
                    </ImageBox>
                </InfoBox>
            </InfoGreyBox>

            <HistoryTxtBox>
                <InfoBox>
                    <TextBox>
                        <h1>상품 판매 이력</h1>
                    </TextBox>
                </InfoBox>
            </HistoryTxtBox>
            <InfoHistoryBox>
                <ul>
                    <li className="left">
                        <HisDoLeft></HisDoLeft>
                        <HisTxtLeft>
                            <h1>2022</h1>
                            <ul>
                                <li><p><b></b> I사 표준특허 DB 수집 및 AI 기반의 핵심키워드 DB 가공</p></li>
                                <li><p><b></b> H사 표준특허 자동 분류를 위한 표준특허 DB가공 및 AI기반의 자동 분류 모델 개발</p></li>
                                <li><p><b></b> K사 상표 데이터 셋 구매 및 구매 데이터로부터 심사 정보 유형별(필드별)로 가공 제공</p></li>
                                <li><p><b></b> T사 특허-기업 융합 DB 구축 및 AI 가공</p></li>
                                <li><p><b></b> E사 특허 빅데이터 수집 및 AI기반의 자동 분류, 핵심 키워드 DB 가공</p></li>
                                <li><p><b></b> S사 개인 주도형 의약품 통합 관리 시스템 개발 및 사업화를 위한 특허 데이터 AI가공</p></li>
                                <li><p><b></b> S사 재생에너지 발전량 모니터링 전처리 과정 및 전처리 데이터 AI가공</p></li>
                                <li><p><b></b> J사 태양열 기술개발 관련 특허 탐색 및 주요 Topic 데이터 가공</p></li>
                                <li><p><b></b> H사 간병인과 환자, 질병정보에서 의미있는 키워드 도출 및 분석</p></li>
                            </ul>
                        </HisTxtLeft>
                    </li>

                    <li className="right">
                        <HisDoRight></HisDoRight>
                        <HisTxtRight>
                            <h1>2021</h1>
                            <ul className="his_list">
                                <li><p><b></b> I사 4D LiDAR의 사업화를 위해 핵심 기술의 주요 선진사, 경쟁사 IP 포트폴리오 분석 필요 및 향후 예상되는 지재권 분쟁 예방에 필요한 특허데이터 가공</p></li>
                                <li><p><b></b> T사 특허기반 수요기업 탐색 시스템을 위한 특허 데이터 AI 가공</p></li>
                            </ul>
                        </HisTxtRight>
                    </li>

                    <li className="left">
                        <HisDoLeft></HisDoLeft>
                        <HisTxtLeft>
                            <h1>2020</h1>
                            <ul>
                                <li><p><b></b> I사 기술 별 특허 수집 및 기술/시장 트렌드 분석 데이터 가공</p></li>
                                <li><p><b></b> I사 최근 10년 국내 특허 기반의 대리인 정보 가공</p></li>
                                <li><p><b></b> L사 국내 코스닥 기업의 보유특러와 재무정보 기반의 기술-시장데이터 융합 가공 및 대학/연구소 보유 특허 기반의 연구자 정보 전처리</p></li>
                                <li><p><b></b> M사 핵심 특허의 전, 후방 인용 및 패밀리 특허 수집</p></li>
                                <li><p><b></b> E사 공공연(출연연 및 대학) 대상 Smart-IPMS 구축을 위한 특허 데이터 가공</p></li>
                                <li><p><b></b> T사 전후방인용 정보 수집 및 가공을 통한 경쟁사 탐색과 서비스 고도화</p></li>
                            </ul>
                        </HisTxtLeft>
                    </li>

                    <li className="right">
                        <HisDoRight></HisDoRight>
                        <HisTxtRight>
                            <h1>2019</h1>
                            <ul className="his_list">
                                <li><p><b></b> K 연구소의 보유 기술 연계 가능 기업 탐색을 위한 맞춤형 컨설팅 및 데이터 판매</p></li>
                                <li><p><b></b> H사의 내부 발명자별 포트폴리오 분석 및 관련 경쟁사 전문가의 전문 연구 기술분야 탐색 데이터 제공</p></li>
                                <li><p><b></b> K 연구소의 보안통신분야 유망기술 발굴을 위한 특허 전수 조사 및 동향 분석 데이터 제공</p></li>
                                <li><p><b></b> K 연구소의 유망업종 발굴을 위한 산업별 생태계 변화 분석 수행 및 K연구소의 산업별 분류를 바탕으로 자사 시스템 기반의 향후 산업별 유망업종 발굴 데이터 제공</p></li>
                                <li><p><b></b> N사의 요청으로 특정 기업에 대한 기술력보유 현황 분석 및 딥러닝 기반의 유사 기술력보유기업 추천 데이터 제공</p></li>
                                <li><p><b></b> L사의 주요 관심 기술 분야에 대한 기술변화 분석, 분야별 전문가 분석, L사의 주요 기술현황, 관심 기술 분야의 기술 포트폴리오 분석 등 특정 산업의 전반적인 분석 데이터 제공</p></li>
                                <li><p><b></b> 일본 수출규제(플루오린 폴리이미드, 이차전지 분리막, 탄소복합소재 등) 등 사회적 이슈와 관련하여 국내 기술 보유 기업 탐색, 해외 기술보유 기업 포트폴리오 분석 등 데이터 제공</p></li>
                                
                            </ul>
                        </HisTxtRight>
                    </li>
                    
                </ul>
            </InfoHistoryBox>
        </>
    );
}
export default DataVoucher;


const IntroBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  padding-top: 50px;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 30vh;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 20vh;
  }
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  span{
    padding: 0 10px;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    color: #919191;
    margin-top: 2rem;
    max-width: 1200px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 1rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  border-bottom: 2px solid rgb(234, 84, 19);
  @media ${({theme}) => theme.device.mobile} {
    font-size: 15px;
    margin-bottom: 20px;
  }
  p {
    padding-bottom: 10px;
  }
`;

const Divide = styled.div`
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 50px;
`;

const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 100px 10px;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
    padding: 50px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }
  div {
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InfoGreyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //max-width: 1200px;
  min-height: 65vh;
  padding: 100px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
    padding: 50px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }

  div {
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
`

const TextBox = styled.div`
  padding: 0 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.tabletL} {
    width: 90%;
  }  
  div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: black;
    letter-spacing: -2px;
    font-size: 4rem;
    font-weight: 700;
    line-height: 5rem;
    text-align: center;
    margin-bottom: 4rem;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 1rem;
      line-height: 1.3rem;
      margin-bottom: 1.5rem;
    }
  }
    h2 {
      color: #EA5413;
      letter-spacing: -2px;
      font-size: 2.7rem;
      font-weight: 700;
      line-height: 3.5rem;
      text-align: center;
      margin-top: -50px;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 2rem;
        line-height: 2.3rem;
        margin-top: -20px;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.85rem;
        line-height: 1.2rem;
      }
    }
}

  span {
    color: #EA5413;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    margin-top: 1rem;
    text-align: center;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }
`;

const TextBoxInfo = styled.div`
  p {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 550;
    line-height: 3rem;
    color: #919191;
    margin-top: 20px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 10px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 5px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 0.5rem;
      line-height: 1rem;
    }
  }
  ul{
    li{
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
      color: #919191;
      ::before {
        content: '▶';
        margin: 10px;
      }
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 1rem;
        line-height: 1.5rem;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 0.5rem;
        line-height: 1rem;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 0.4rem;
        line-height: 1rem;
      }
    }
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 900px;
    max-height: 800px;
    margin: 50px;
    @media ${({theme}) => theme.device.tabletL} {
      max-width: 650px;
      max-height: 400px;
      margin: 20px;
    }
    @media ${({theme}) => theme.device.tablet} {
      max-width: 400px;
      max-height: 400px;
      margin: 10px;
    }
    @media ${({theme}) => theme.device.mobile} {
      max-width: 300px;
      max-height: 200px;
      margin: 10px;
    }
    @media ${({theme}) => theme.device.mobileS} {
      max-width: 260px;
    }
  }
`;

//히스토리
const HistoryTxtBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //min-height: 65vh;
  padding: 50px 10px;
  @media ${({theme}) => theme.device.tablet} {
    //min-height: 20vh;
    padding: 30px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 20px 10px;
  }
`;

const InfoHistoryBox = styled.div`
  padding: 100px 20px 70px;
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 2;
  @media ${({theme}) => theme.device.mobile} {
    width: 300px;
    padding: 50px 0px 70px;
  }
  @media ${({theme}) => theme.device.mobileS} {
    width: 270px;
  }
  ::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background: #e5e5e5;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }

  ul {
    width: 100%;
    position: relative;
    text-align: right;
    z-index: 1;
    .left {
      margin-left: 9px;
      width: 50%;
      display: flex;
      flex-direction: row-reverse;
      text-align: right;
      align-items: flex-start;
      @media ${({theme}) => theme.device.mobile} {

        margin-left: 0px;
        width: 100%;

        text-align: left;
        display: flex;
        flex-direction: row;
      }
    }

    .right {
      justify-content: flex-start;
      text-align: left;
      margin-left: calc(50% - 9px);
      flex-direction: row;
      @media ${({theme}) => theme.device.mobile} {
        margin-left: 0px;
        width: 100%;
      }
    }
  }
`;


const HisDoLeft = styled.div`
  display: flex;
  align-items: center;

  ::before {
    content: '';
    display: block;
    width: 90px;
    height: 1px;
    background: #0a2689;
    @media ${({theme}) => theme.device.tabletL} {
      width: 70px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 50px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;

  ::after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 3px solid #0a2689;
    border-radius: 50%;
    @media ${({theme}) => theme.device.tabletL} {
      width: 15px;
      height: 15px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 13px;
      height: 13px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;
`;
const HisDoRight = styled.div`
  display: flex;
  align-items: center;

  ::after {
    content: '';
    display: block;
    width: 90px;
    height: 1px;
    background: #0a2689;
    @media ${({theme}) => theme.device.tabletL} {
      width: 70px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 50px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;

  ::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 3px solid #0a2689;
    border-radius: 50%;
    @media ${({theme}) => theme.device.tabletL} {
      width: 15px;
      height: 15px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 13px;
      height: 13px;
    }
    @media ${({theme}) => theme.device.mobile} {
      display: none;
    }
  }
;
`;

const HisTxtLeft = styled.div`
  margin-right: 30px;
  height: 100%;
  position: relative;
  top: -16px;
  @media ${({theme}) => theme.device.tabletL} {
    margin-right: 20px;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin-right: 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    top: -36px;
    justify-content: flex-start;
    text-align: left;
    margin-left: 10px;
    flex-direction: row;
  }
  h1 {
    font-size: 40px;
    font-weight: 600;
    color: #0a2689;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 35px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 30px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-weight: 600;
      color: #0a2689;
      font-family: 'Montserrat';
      margin-bottom: 20px;
    }
  }
;

  ul {
    @media ${({theme}) => theme.device.mobile} {
      text-align: left;
    }
    li {
      //margin-bottom: 10px;
      margin: 0 0 10px 0;
      position: relative;
      padding-right: 15px;
      width: 100%;
      @media ${({theme}) => theme.device.mobile} {
        padding-left: 15px;
        padding-right: 0px;
      }
      ::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #6677fa;
        position: absolute;
        right: 0;
        top: 12px;
        @media ${({theme}) => theme.device.mobile} {
          left: 0;
        }
      }

      p {
        font-size: 15px;
        letter-spacing: -1px;
        font-weight: 400;
        color: #333;
        line-height: 28px;
        font-family: 'NanumSquare', sans-serif;
        word-break: keep-all;
        @media ${({theme}) => theme.device.tablet} {
          font-size: 14px;
          line-height: 26px;
        }
        b {
          font-weight: 700;
          color: #000;
        }
      }
    }
  }
`;

const HisTxtRight = styled.div`
  height: 100%;
  position: relative;
  top: -36px;

  justify-content: flex-start;
  text-align: left;
  margin-left: 150px;
  flex-direction: row;
  @media ${({theme}) => theme.device.tabletL} {
    margin-left: 110px;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin-left: 80px;
  }
  @media ${({theme}) => theme.device.mobile} {
    margin-left: 10px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    color: #0a2689;
    font-family: 'Montserrat';
    margin-bottom: 20px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 35px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 30px;
    }
  }
;

  ul {
    li {
      margin: 0 0 10px 0;
      position: relative;
      padding-left: 15px;
      width: 100%;

      ::before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #6677fa;
        position: absolute;
        left: 0;
        top: 12px;
      }

      p {
        font-size: 15px;
        letter-spacing: -1px;
        font-weight: 400;
        color: #333;
        line-height: 28px;
        font-family: 'NanumSquare', sans-serif;
        word-break: keep-all;

        justify-content: flex-start;
        text-align: left;
        margin-left: calc(0%);
        flex-direction: row;
        @media ${({theme}) => theme.device.tablet} {
          font-size: 14px;
          line-height: 26px;
        }
        b {
          font-weight: 700;
          color: #000;
        }
      }
    }
  }
`;