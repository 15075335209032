import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faSquareRight} from "@fortawesome/free-solid-svg-icons";
import {faSquareCheck} from "@fortawesome/free-solid-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {faCircleRight} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import event_popup from "../../images/event_popup.png";
import sample_report from "../../pdf/sample_report.pdf"

const goResearchall = "https://researchall.net"
const goNoticeResearchall ="https://researchall.net/Help/noticeDetail?data=1084&bbsKind=2"
function MainPopup({ setShowMainPop }) {
    const closePop = () => {
        setShowMainPop(false);
    };
    const closeTodayPop = () => {
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem("homeVisited", expires);
        // 현재 시간의 24시간 뒤의 시간을 homeVisited에 저장
        setShowMainPop(false);
    };
    return (
        <PopupWrapper>
            <PopupBox>
                <div>
                    <img src={event_popup} alt="팝업이미지"/>
                    <MoveBtnBox>
                        <button onClick={()=>{window.open(goResearchall)}}>
                            <FontAwesomeIcon icon={faCircleRight} />
                            <span>ResearchAll<p>바로가기</p></span>
                        </button>
                        <a href={sample_report} download="sample_report">
                        <button>
                            <FontAwesomeIcon icon={faDownload} />
                            <span>샘플 보고서 <p>다운받기</p></span>
                        </button>
                        </a>
                        {/*<button onClick={()=>{window.open(goNoticeResearchall)}}>*/}
                        {/*    <FontAwesomeIcon icon={faSquareCheck} />*/}
                        {/*    <span>런칭 이벤트 <p>확인하기</p></span>*/}
                        {/*</button>*/}
                    </MoveBtnBox>
                </div>
                <button onClick={closeTodayPop}>오늘 하루 열지 않기</button>
                <button onClick={closePop}>닫기</button>
            </PopupBox>
        </PopupWrapper>
    );

}
export default MainPopup;

const PopupWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(131, 69, 69, 0);
  transition: background-color .4s;
`;

const PopupBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  box-shadow: rgba(65, 65, 65, 0.56) 0px 8px 15px;
  @media ${({theme}) => theme.device.tabletL} {
    width: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    width: 320px;
  }
  @media ${({theme}) => theme.device.mobile} {
    width: 230px;
  }
  div {
    background-color: #273EEF;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    //padding-bottom: 50px;
    // @media ${({theme}) => theme.device.tabletL} {
    //   padding-bottom: 40px;
    // }
    // @media ${({theme}) => theme.device.tablet} {
    //   padding-bottom: 30px;
    // }
    // @media ${({theme}) => theme.device.mobile} {
    //   padding-bottom: 20px;
    // }
    img{
      width: 100%;
    }
    // button {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 260px;
    //   background-color: rgba(52, 52, 52, 0.6);
    //   border-radius: 30px;
    //   color: white;
    //   padding: 16px;
    //   font-size: 16px;
    //   border: none;
    //   cursor: pointer;
    //   transition: all 0.5s linear;
    //   @media ${({theme}) => theme.device.tablet} {
    //     width: 200px;
    //     padding: 10px;
    //     font-size: 14px;
    //   }
    //   @media ${({theme}) => theme.device.mobile} {
    //     width: 170px;
    //     padding: 10px;
    //     font-size: 10px;
    //   }
    //   @media ${({theme}) => theme.device.mobileS} {
    //     width: 150px;
    //   }
    //
    //   &:hover {
    //     transform: translateX(20px)
    //   }
    //
    //   span {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding-right: 10px;
    //
    //     p {
    //       color: white;
    //       font-size: 16px;
    //       padding: 0 0 0 5px;
    //       @media ${({theme}) => theme.device.tablet} {
    //         font-size: 14px;
    //       }
    //       @media ${({theme}) => theme.device.mobile} {
    //         font-size: 10px;
    //       }
    //     }
    //   }
    // }
  }

  button {
    cursor: pointer;
    width: 50%;
    height: 60px;
    font-size: 18px;
    border: 1px solid rgb(227, 227, 227);
    background-color: rgb(242, 242, 242);
    @media ${({theme}) => theme.device.tabletL} {
      height: 50px;
      font-size: 15px;
    }
    @media ${({theme}) => theme.device.tablet} {
      height: 40px;
      font-size: 10px;
    }
    :hover {
      background-color: rgba(173, 173, 173, 0.6); 
      transition: all .2s ease;
    }
  }
`;

const MoveBtnBox = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center;
  //flex-direction: column;
  width: 100%;
  @media ${({theme}) => theme.device.mobile} {
    flex-direction: column;
  }
  button{
    display: flex; flex-direction:column; 
    justify-content: center;align-items: center;width: 160px;
    height: 120px; background-color: rgba(52, 52, 52, 0.6);
    border-radius: 30px;color: white;padding: 16px;
    font-size: 16px;border: none;cursor: pointer;margin: 0 5px 30px;
    @media ${({theme}) => theme.device.tabletL} {
      width: 140px;  height: 120px;
      padding: 8px;
      font-size: 14px;
    }
        @media ${({theme}) => theme.device.tablet} {
        width: 95px;  height: 120px;
        padding: 8px;
        font-size: 14px;
      }
        @media ${({theme}) => theme.device.mobile} {
        width: 150px; height: 43px;
        padding: 5px;  border-radius: 20px;  margin: 0 5px 8px;
          font-size: 13px;
      }
  svg {
    width: 30px; height: 30px; padding-bottom: 15px;
    @media ${({theme}) => theme.device.tablet} {
      width: 25px; height: 25px;
    }
    @media ${({theme}) => theme.device.mobile} {
      width: 20px; height: 20px;
      padding-bottom: 10px;
      display: none;
    }

  }
    :hover {opacity: .9; transition: all .2s ease;}
  }
`;
