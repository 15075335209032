import React from 'react';
import styled from 'styled-components';
import researchall from "../../images/researchall_small_banner.png"
import bizip from "../../images/bizip_small_banner.png"
import ai from "../../images/ai_small_banner.png"
import data from "../../images/data_small_banner.png"
import { useNavigate } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function ServicesBox() {
    //페이지 이동
    const navigate = useNavigate();

    return (
        <Container>
            <ServicesInner>
                <ServicesTitle>
                    <p>Services</p>
                    <h1>서비스 소개</h1>
                </ServicesTitle>
                <ServicesList>
                    <li onClick={() => {navigate(`${'/services?category=researchall'}`)}}>
                        <div>
                            <h1>ResearchAll</h1>
                            <p>연구정보 융합 분석 플랫폼</p>
                        </div>
                        <button>
                            <h1>ResearchAll</h1>
                            <span>more view<FontAwesomeIcon icon={faChevronRight}/></span>
                        </button>
                    </li>
                    <li onClick={() => {navigate(`${'/services?category=bizip'}`)}}>
                        <div>
                            <h1>BIZ-IP</h1>
                            <p>기업 기술력 포트폴리오 분석 플랫폼</p>
                        </div>
                        <button>
                            <h1>BIZ-IP</h1>
                            <span>more view<FontAwesomeIcon icon={faChevronRight}/></span>
                        </button>
                    </li>
                    <li onClick={() => {navigate(`${'/services?category=aivoucher'}`)}}>
                        <div>
                            <h1>AI 바우처(공급기관)</h1>
                            <p>연구정보 기반의 AI 분석 전문성 보유</p>
                        </div>
                        <button>
                            <h1>AI 바우처(공급기관)</h1>
                            <span>more view<FontAwesomeIcon icon={faChevronRight}/></span>
                        </button>
                    </li>
                    <li onClick={() => {navigate(`${'/services?category=datavoucher'}`)}}>
                        <div>
                            <h1>데이터 바우처(공급기관)</h1>
                            <p>연구정보(논문,특허,기업정보 등)<br/>수집·분석·모델링</p>
                        </div>
                        <button>
                            <h1>데이터 바우처(공급기관)</h1>
                            <span>more view<FontAwesomeIcon icon={faChevronRight}/></span>
                        </button>
                    </li>
                </ServicesList>
            </ServicesInner>
        </Container>
    );
}

const Container = styled.div`
  text-align: center;
  padding: 90px 0 140px;
  //position: relative;
  //z-index: 1;
  background-color: #fff;
  @media ${({theme}) => theme.device.tabletL} {
    padding: 60px 0 90px;
  }
  @media ${({theme}) => theme.device.tablet} {
    padding: 50px 0 60px;
  }
  @media ${({theme}) => theme.device.mobile} {
    padding: 30px 0 40px;
  }
`;

const ServicesInner = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

const ServicesTitle = styled.div`
  width: 100%;
  p {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #EA5413;
    text-transform: uppercase;
    letter-spacing: 9px;
    font-family: 'Montserrat';
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 14px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 12px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 9px;
    }
  }
  h1 {
    display: flex;
    justify-content: center;
    font-size: 48px;
    font-weight: 800;
    color: #000;
    font-family: 'NanumSquare', sans-serif;
    margin-top: 15px;
    @media ${({theme}) => theme.device.tabletL} {
      font-size: 38px;
    }
    @media ${({theme}) => theme.device.tablet} {
      font-size: 32px;
    }
    @media ${({theme}) => theme.device.mobile} {
      font-size: 25px;
    }
  }
`;

const ServicesList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  @media ${({theme}) => theme.device.tablet} {
    margin-top: 30px;
  }
  @media ${({theme}) => theme.device.tablet} {
    margin-top: 30px;
  }
  @media ${({theme}) => theme.device.mobile} {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  li {
    margin: 10px;
    width: 25%;
    height: 400px;
    background-color: rgb(0,0,0,0.8);
    cursor: pointer;
    display: flex;
    text-align: center;
    position: relative;
    @media ${({theme}) => theme.device.tabletL} {
      height: 320px;
    }
    @media ${({theme}) => theme.device.tablet} {
      height: 250px;
      margin: 5px 2.5px;
    }
    @media ${({theme}) => theme.device.mobile} {
      height: 120px;
      width: 100%;
    }
    :nth-child(1) {
      background-image: url(${researchall});
      background-size: 100% 100%;
      background-position: center center;
      background-size: cover;
      filter: brightness(70%);
      &:hover{
        background-image: none;
        div{ display:none;}
        button { visibility: visible; opacity:1;}
      }
    }
    :nth-child(2) {
      background-image: url(${bizip});
      background-size: 100% 100%;
      background-position: center center;
      background-size: cover;
      filter: brightness(70%);
      &:hover{
        background-image: none;
        div{ display:none;}
        button { visibility: visible; opacity:1;}
      }
    }
    :nth-child(3) {
      background-image: url(${ai});
      background-size: 100% 100%;
      background-position: center center;
      background-size: cover;
      filter: brightness(70%);
      &:hover{
        background-image: none;
        div{ display:none;}
        button { visibility: visible; opacity:1;}
      }
    }
    :nth-child(4) {
      background-image: url(${data});
      background-size: 100% 100%;
      background-position: center center;
      background-size: cover;
      filter: brightness(70%);
      &:hover{
        background-image: none;
        div{ display:none;}
        button { visibility: visible; opacity:1;}
      }
    }
    div {
      width: 100%;
      //height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //justify-content: flex-end;
      position: absolute;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      top: 312px;
      height: 22%;
      padding: 15px 10px;
      @media ${({theme}) => theme.device.tabletL} {
        top: 250px;
        padding: 15px 8px;
      }
      @media ${({theme}) => theme.device.tablet} {
        top: 180px;
        padding: 15px 1px;
        height: 28%;
      }
      @media ${({theme}) => theme.device.mobile} {
        top: 85px;
        height: 30%;
      }
      h1 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        @media ${({theme}) => theme.device.tabletL} {  font-size: 15px;  }
        @media ${({theme}) => theme.device.tablet} {  font-size: 10px;  }
        @media ${({theme}) => theme.device.mobile} {  padding-top: 5px;  }
      }
      p {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        @media ${({theme}) => theme.device.tabletL} {  font-size: 12px;}
        @media ${({theme}) => theme.device.tablet} {  font-size: 5px;line-height: 12px; }
        @media ${({theme}) => theme.device.mobile} {  margin-top: 5px;  padding-bottom: 5px; }
      }
    }
    button {
      cursor: pointer;
      visibility: hidden; 
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      background: inherit;
      opacity:0; transition: all 1.2s;
      h1 {
        color: #fff;
        padding: 20px 0;
        font-size: 20px;
        font-weight: 600;
      }
      span {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #fff;
        padding: 10px;
        svg {padding-left: 5px;}
        :hover {
          background-color: #EA5413;
          border: 1px solid #EA5413;;
        }
      }
    }
  }
`;

export default ServicesBox;