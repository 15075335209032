import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Services from "./pages/Services";
import AboutUs from './pages/AboutUs';
import ScrollToTop from "./components/scrollToTop";

const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/aboutus" element={<AboutUs />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;