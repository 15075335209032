import React from 'react';
import styled from "styled-components";
import Vision from "../AboutUs/Vision";
import Info from "../AboutUs/Info";
import History from "../AboutUs/History"
// import Member from "./Memner";
import PR from "./PR";

function AboutUsContents(props) {
    const {category} = props;

    return (
        <Container>
            {category === 'vision' && <Vision/>}
            {category === 'info' && <Info/>}
            {/*{category === 'member' && <Member/>}*/}
            {category === 'history' && <History/>}
            {category === 'PR' && <PR/>}
        </Container>
    );
}

export default AboutUsContents;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
