import { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";

import MainBanner from "../components/Home/MainBanner";
import ServicesBox from "../components/Home/ServicesBox"
import MainPopup from "../components/Home/MainPopup";
import videoUrl from "../video/techdna-introduction.mp4";
import poster from "../images/techdna_poster.png";

function Home() {
    // 팝업
    // 기본 세팅 값은 false
    const [showMainPop, setShowMainPop] = useState(false);

    // localStorage에 homeVisited 조회
    const HOME_VISITED = localStorage.getItem("homeVisited");
    useEffect(() => {
        const today = new Date();
        const handleMainPop = () => {
            if (HOME_VISITED && HOME_VISITED > today) {
                // 현재 date가 localStorage의 시간보다 크면 return
                return;
            }
            if (!HOME_VISITED || HOME_VISITED < today) {
                // 저장된 date가 없거나 today보다 작다면 popup 노출
                setShowMainPop(true);
            }
        };
        window.setTimeout(handleMainPop, 1000); // 1초 뒤 실행
    }, [HOME_VISITED]);

    return (
        <>
        {showMainPop && <MainPopup setShowMainPop={setShowMainPop}></MainPopup>}
            <Header/>
            <HomeBox>
                {/*메인 베너 컴포넌트*/}
                <MainBanner />
                {/*서비스 소개 컴포넌트*/}
                <ServicesBox />

                <TDVideo>
                    <VideoBox>
                        <video src={videoUrl} poster={poster} loop controls />
                    </VideoBox>
                </TDVideo>
            </HomeBox>
            <Footer/>
        </>
    );
}

export default Home;



const HomeBox = styled.div`
  margin-top: 90px;
  @media ${({theme}) => theme.device.mobile} {
    margin-top: 60px;
  }
`;

const TDVideo = styled.div`
  display: flex;
  text-align: center;
  padding: 0px 0px 140px;
  background-color: #fff;
  margin: 10px;
  @media ${({theme}) => theme.device.tabletL} {
    padding: 0px 0px 80px;
  }
  @media ${({theme}) => theme.device.tablet} {
    padding: 0px 0px 40px;
  }
    div {
      background-color: #AAABB3;
      max-width: 1300px;
      margin: 0 auto;
    }
`;

const VideoBox = styled.div`
  border: 3px solid #2d2d2d;
  max-width: 1000px;
  margin: 0 auto;
  video {
    width: 100%;
    padding: 10px;
  }
`;
