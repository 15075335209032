import React from 'react';
import styled from "styled-components";
import banner_services from "../../images/banner_services.jpg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";


// 사이트 이동 Url 변수
const goPRCenter = "https://blog.naver.com/prologue/PrologueList.naver?blogId=techdna"

function ServiceBanner(props) {
    const {category} = props;

    return (
        <Container>
            <Banner>
                <div>
                    {category === 'vision' &&
                        <>
                            <p>ABOUT US</p>
                            <h1>비전 & 가치</h1>
                        </>
                    }
                    {category === 'info' &&
                        <>
                            <p>ABOUT US</p>
                            <h1>수상 및 인증</h1>
                        </>
                    }
                    {category === 'history' &&
                        <>
                            <p>ABOUT US</p>
                            <h1>주요연혁</h1>
                        </>
                    }
                    {/*{category === 'member' &&*/}
                    {/*    <>*/}
                    {/*        <p>ABOUT US</p>*/}
                    {/*        <h1>MEMBER</h1>*/}
                    {/*    </>*/}
                    {/*}*/}
                    {category === 'PR' &&
                        <>
                            <p>ABOUT US</p>
                            <h1>홍보센터</h1>
                            <button onClick={()=>{window.open(goPRCenter)}}>
                                <span>홍보센터<p>바로가기</p></span>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        </>
                    }
                </div>
                <img src={banner_services} alt="banner_services"/>
            </Banner>
        </Container>
    );
}

export default ServiceBanner;


const Container = styled.div`
  background-color: #AAABB3;
  height: 600px;
  @media ${({theme}) => theme.device.tabletL} {
    height: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    height: 450px;
  }
  @media ${({theme}) => theme.device.mobile} {
    height: 300px;
  }
`;

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.tabletL} {
    height: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    height: 450px;
  }
  @media ${({theme}) => theme.device.mobile} {
    height: 300px;
  }

  div {
    position: absolute;
    max-width: 1700px;
    width: 100%;
    margin-top: 90px;
    padding: 0 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 5px;

    white-space:pre-wrap;
    animation: fadeInUp 1.5s;
    @media ${({theme}) => theme.device.tablet} {
      padding: 0 20px;
    }
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 50%, 0);
      }
      to {
        opacity: 1;
        transform: translateZ(0);
      }
    }
    h1 {
      font-size: 52px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 1px;
      padding-top: 10px;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 48px;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 38px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        font-size: 30px;
      }
    }

    p {
      font-size: 20px;
      color: rgb(234, 84, 19);
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 18px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        font-size: 14px;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 260px;
      background-color: rgb(234, 84, 19);
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      margin: 30px;
      transition: all 0.5s linear;
      @media ${({theme}) => theme.device.tabletL} {
        margin-top: 50px;
      }
      @media ${({theme}) => theme.device.tablet} {
        width: 200px;
        padding: 10px;
        font-size: 14px;
      }
      @media ${({theme}) => theme.device.mobile} {
        width: 170px;
        padding: 10px;
        font-size: 10px;
        margin-top:10px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        width: 150px;
      }
      &:hover {
        transform: translateX( 20px )
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;

        p {
          color: white;
          font-size: 16px;
          padding: 0 0 0 5px;
          @media ${({theme}) => theme.device.tablet} {
            font-size: 14px;
          }
          @media ${({theme}) => theme.device.mobile} {
            font-size: 10px;
          }
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

