import React, { useState } from 'react';
import styled from "styled-components";
import techdnaLogo from "../../images/techdna_logo.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
function Home() {

    const navigate = useNavigate();
    const goHome = () => navigate("/");

    // 패밀리사이트 이동 Url 변수
    const goResearchall = "https://researchall.net"
    const goBizIp = "https://biz-ip.com/"
    const GoExpertPlatform = "https://expert-platform.techdna.kr/"

    // services_ 메뉴 열고닫기
    const [isServiceMenuDropDownOpen, setServiceMenuDropDownOpen] = useState(false);

    // aboutus_ 메뉴 열고닫기
    const [isAboutUsMenuDropDownOpen, setAboutUsMenuDropDownOpen] = useState(false);

    return (
            <HeaderWrap>
                <HeaderBox>
                    <HeaderInner>
                        <Logo>
                            <img alt='techdna_logo' src={techdnaLogo}
                                 onClick={() => {
                                navigate('/');
                                window.location.reload();
                            }}/>
                        </Logo>
                        <TopNav>
                            <Gnb>
                                <li onClick={goHome}><span>Home</span></li>
                                <li
                                //     onClick={() => {navigate(`${'/services?category=researchall'}`);
                                //     window.location.reload();
                                // }}
                                    onMouseEnter={() => setServiceMenuDropDownOpen(true)}
                                    onMouseLeave={() => setServiceMenuDropDownOpen(false)}
                                >
                                    <span>Services</span>
                                </li>
                                {/*<li onClick={goAboutUs}*/}
                                <li
                                //     onClick={() => {navigate(`${'/aboutus?category=vision'}`);
                                //     window.location.reload();
                                // }}
                                    onMouseEnter={() => setAboutUsMenuDropDownOpen(true)}
                                    onMouseLeave={() => setAboutUsMenuDropDownOpen(false)}
                                >
                                    <span>About Us</span>
                                </li>
                            </Gnb>
                            <HdBtnWr>
                                <HFamWr>
                                        <button>
                                            <span>Family Site 바로가기</span>
                                            <FontAwesomeIcon icon={faCaretDown}/>
                                        </button>
                                        <ul>
                                            <li onClick={()=>{window.open(goResearchall)}}>ResearchALL</li>
                                            <li onClick={()=>{window.open(goBizIp)}}>BIZ-IP</li>
                                            {/*<li onClick={()=>{window.open(GoExpertPlatform)}}>expert-platform</li>*/}
                                        </ul>
                                </HFamWr>
                            </HdBtnWr>
                        </TopNav>
                    </HeaderInner>

                    {isServiceMenuDropDownOpen &&
                    <div className="nav_modal"
                         onMouseEnter={() => setServiceMenuDropDownOpen(true)}
                         onMouseLeave={() => setServiceMenuDropDownOpen(false)}>
                        <div>
                            <ul>
                                <li onClick={() => {
                                    navigate(`${'/services?category=researchall'}`);
                                    window.location.reload();
                                }}>ResearchAll</li>
                                <li onClick={() => {
                                    navigate(`${'/services?category=bizip'}`);
                                    window.location.reload();
                                }}>BIZ-IP</li>
                                <li onClick={() => {
                                    navigate(`${'/services?category=aivoucher'}`);
                                    window.location.reload();
                                }}>AI 바우처</li>
                                <li onClick={() => {
                                    navigate(`${'/services?category=datavoucher'}`);
                                    window.location.reload();
                                }}>데이터 바우처</li>
                            </ul>
                        </div>
                    </div>
                    }
                    {isAboutUsMenuDropDownOpen &&
                        <div className="nav_modal"
                             onMouseEnter={() => setAboutUsMenuDropDownOpen(true)}
                             onMouseLeave={() => setAboutUsMenuDropDownOpen(false)}>
                            <div>
                                <ul>
                                    <li onClick={() => {
                                        navigate(`${'/aboutus?category=vision'}`);
                                        window.location.reload();
                                    }}>비전&가치</li>
                                    <li onClick={() => {
                                        navigate(`${'/aboutus?category=info'}`);
                                        window.location.reload();
                                    }}>수상 및 인증</li>
                                    <li onClick={() => {
                                        navigate(`${'/aboutus?category=history'}`);
                                        window.location.reload();
                                    }}>주요연혁</li>
                                    {/*<li onClick={() => {*/}
                                    {/*    navigate(`${'/aboutus?category=member'}`);*/}
                                    {/*    window.location.reload();*/}
                                    {/*}}>Member</li>*/}
                                    <li onClick={() => {
                                        navigate(`${'/aboutus?category=PR'}`);
                                        window.location.reload();
                                    }}>홍보센터</li>
                                </ul>
                            </div>
                        </div>
                    }
                </HeaderBox>
            </HeaderWrap>
    );
}
export default Home;

const HeaderWrap = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  width: 100%;
  height: 90px;
  z-index: 57;
  border-bottom: 1px solid #ddd;
  position: relative;
  left: 0;
  top: 0;
  transition: all .3s ease;
  position: fixed;
  z-index: 100;
  box-shadow: 0 10px 20px rgba(39, 52, 63, 0.1);
  @media ${({theme}) => theme.device.mobile} {
    height: 60px;
  }
`;

const HeaderBox = styled.div`
  min-width: 250px;
  max-width: 100%;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 6;
  transition: all .3s ease;
  margin: 0 auto;
  position: relative;
  height: 100%;
  .nav_modal {
    display: none;
    position: absolute;
    justify-content: center;
    background-color: #fff;
    border-top: 1px solid #ddd;
    padding: 30px 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    width: 100%;
    @media ${({theme}) => theme.device.tablet} {
      padding: 20px 0;
    }
    div {
      display: flex;
      justify-content: center;
      ul{
        display: flex;
        justify-content: flex-end;
        padding: 0 15px;
        width: 100%;
        margin-right: 265px;
        max-width: 1540px;
        @media ${({theme}) => theme.device.tabletL} {
          margin-right: 210px;
        }
        @media ${({theme}) => theme.device.tablet} {
          margin-right: 0px;
          font-size: 0.9rem;
        }
        @media ${({theme}) => theme.device.mobile} {
          font-size: 0.7rem;
          padding: 0px;
        }
        li { 
          margin: 0 40px; 
          cursor: pointer;
          text-align: center;
          min-width: 32px;
          @media ${({theme}) => theme.device.tabletL} {
            margin: 0 20px;
          }
          @media ${({theme}) => theme.device.tablet} {
            margin: 0 10px;
          }
          @media ${({theme}) => theme.device.mobile} {
            margin: 0 5px;
          }
          @media ${({theme}) => theme.device.mobileS} {
            margin: 0 3.8px;
          }
        }
      }
      ul li:hover {color: #EA5413;}
    }
  }
  :hover .nav_modal {
    display: block;
  }
`;

const HeaderInner = styled.div`
  max-width: 1740px;
  padding: 0 20px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  align-items: center;
  @media ${({theme}) => theme.device.mobile} {
    padding: 0 10px;
  }
`;

const Logo = styled.div`
  float: left;
  box-sizing: border-box;
  cursor: pointer;
  img {
    transition: all .3s ease;
    vertical-align: middle;
    font-size: 1em;
    width: 200px;
    @media ${({theme}) => theme.device.tabletL} {
      width: 180px;
    }
    @media ${({theme}) => theme.device.tablet} {
      width: 150px;
    }
    @media ${({theme}) => theme.device.mobile} {
      width: 85px;
    }
  }
`;

const TopNav = styled.nav`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.25em;
  font-weight: ${({ theme }) => theme.fontWeight.regular_2};;
  @media ${({theme}) => theme.device.tabletL} {
    font-size: 1.15em;
  }
  @media ${({theme}) => theme.device.tablet} {
    font-size: 1.1em;
  }
  @media ${({theme}) => theme.device.mobile} {
    font-size: 0.90em;
  }
  @media ${({theme}) => theme.device.mobileS} {
    font-size: 0.80em;
  }
`;

const Gnb = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 0 0;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  li {
    width: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0 40px;
    position: relative;
    cursor: pointer;
    height: 100%;
    @media ${({theme}) => theme.device.tabletL} {
      padding: 0 20px;
    }
    @media ${({theme}) => theme.device.tablet} {
      padding: 0 15px;
    }
    @media ${({theme}) => theme.device.mobile} {
      padding: 0 8px;
    }
    @media ${({theme}) => theme.device.mobileS} {
      padding: 0 3px;
    }
    span {
      display: flex;
      justify-content: center;
      text-align: center;
      // @media ${({theme}) => theme.device.mobile} {
      //   padding: 0 0 0 20px;
      // }
      // @media ${({theme}) => theme.device.mobileS} {
      //   padding: 0 0 0 10px;
      // }
    }
    &:hover{
      color: #EA5413;
      border-bottom: 3px solid #EA5413;
    }
  }
`;


const HdBtnWr = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid #ddd;
  padding-left: 65px;
  @media ${({theme}) => theme.device.tabletL} {
    padding-left: 40px;
  }
  @media ${({theme}) => theme.device.tablet} {
    padding-left: 30px;
  }
  @media ${({theme}) => theme.device.tablet} {
    display: none;
  }
`;

const HFamWr = styled.div`
    position: relative;
    display: inline-block;
    button {
      width: 200px;
      background-color: rgb(234, 84, 19);
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      @media ${({theme}) => theme.device.tabletL} {
        width: 180px;
        padding: 12px;
        font-size: 14px;
      }
      @media ${({theme}) => theme.device.tablet} {
        width: 180px;
        padding: 12px;
        font-size: 14px;
      }
      span { padding-right: 10px;}
    }
    ul {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      width: 200px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      li {
        font-size: 15px;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        cursor: pointer;
      }
    }
  ul li:hover { background-color: #ddd; color: #EA5413;}
  :hover ul {display: block;}
  :hover button {background-color: rgb(234, 84, 19);}
`;
