import React from 'react';
import styled from "styled-components";
import certification_01 from "../../images/certification_01.png";
import certification_02 from "../../images/certification_02.png";
import certification_03 from "../../images/certification_03.png";
import certification_04 from "../../images/certification_04.png";
import certification_05 from "../../images/certification_05.png";
import certification_06 from "../../images/certification_06.png";
import certification_07 from "../../images/certification_07.png";
import certification_08 from "../../images/certification_08.png";
import certification_09 from "../../images/certification_09.png";
import certification_10 from "../../images/certification_10.png";
import certification_11 from "../../images/certification_11.png";
import certification_12 from "../../images/certification_12.png";

function Info() {
    return (
        <>
            <InfoWhiteBox>
                <ServiceBox>
                    <ServiceWrap>
                        <div><img src={certification_01} alt="certification_01" /></div>
                        <div><img src={certification_02} alt="certification_02" /></div>
                        <div><img src={certification_03} alt="certification_03" /></div>
                        <div><img src={certification_04} alt="certification_04" /></div>
                        <div><img src={certification_09} alt="certification_09" /></div>
                        <div><img src={certification_10} alt="certification_10" /></div>
                        <div><img src={certification_11} alt="certification_11" /></div>
                        <div><img src={certification_12} alt="certification_12" /></div>

                        <div><img src={certification_05} alt="certification_05" /></div>
                        <div><img src={certification_06} alt="certification_06" /></div>
                        <div><img src={certification_08} alt="certification_08" /></div>
                        <div><img src={certification_07} alt="certification_07" /></div>
                    </ServiceWrap>
                </ServiceBox>
            </InfoWhiteBox>
        </>
    );
}
export default Info;


const InfoWhiteBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  @media ${({theme}) => theme.device.tablet} {
    min-height: 20vh;
  }
  div{
    max-width: 2000px;
    display: flex;
    align-items: center;
  }
`;

const ServiceBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 65vh;
  padding: 50px 10px;
  background-color: #F2F2F2;
  @media ${({theme}) => theme.device.tabletL} {
    padding: 30px 10px;
  }
  @media ${({theme}) => theme.device.mobile} {
    min-height: 50vh;
  }
`;

const ServiceWrap = styled.div`
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  div {
    background-color: #fff;
    padding: 30px;
    margin: 10px;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1.5px solid #fff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(173, 173, 173, 0.25);
    @media ${({theme}) => theme.device.tabletL} {
      width: 40%;
      padding: 20px;
    }
    @media ${({theme}) => theme.device.tablet} {
      padding: 10px;
      width: 45%;
      border-radius: 20px;
    }
    @media ${({theme}) => theme.device.mobile} {
      margin: 4px;
      padding: 5px;
      border-radius: 10px;
    }
    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;