import React from 'react';
import styled from "styled-components";
import banner_services from "../../images/banner_services.jpg"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

// 사이트 이동 Url 변수
const goResearchall = "https://researchall.net"
const goBizIp = "https://biz-ip.com/"

function ServiceBanner(props) {
    const {category} = props;

    return (
        <Container>
            <Banner>
                <div>
                    {category === 'researchall' &&
                        <>
                            <p>SERVICES</p>
                            <h1>ResearchAll</h1>
                            <h4>지식 빅데이터 융합 분석을 통한, <br/>글로벌 연구정보 빅데이터 통합 분석 플랫폼</h4>
                            <button onClick={()=>{window.open(goResearchall)}}>
                                <span>ResearchAll<p>바로가기</p></span>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        </>
                    }
                    {category === 'bizip' &&
                        <>
                            <p>SERVICES</p>
                            <h1>BIZ-IP</h1>
                            <h4>글로벌 기업의 기술력 포트폴리오 분석 플랫폼</h4>
                            <button onClick={()=>{window.open(goBizIp)}}>
                                <span>BIZ-IP<p>바로가기</p></span>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        </>
                    }
                    {category === 'aivoucher' &&
                        <>
                            <p>SERVICES</p>
                            <h1>AI 바우처(공급기관)</h1>
                            <h4>특허 빅데이터 AI기반 솔루션 제공</h4>
                        </>
                    }
                    {category === 'datavoucher' && <>
                        <p>SERVICES</p>
                        <h1>데이터 바우처(공급기관)</h1>
                        <h4>연구정보(논문,특허,기업정보 등)수집·분석·모델링 제공</h4>
                    </>}
                </div>
                <img src={banner_services} alt="banner_services"/>
            </Banner>
        </Container>
    );
}

export default ServiceBanner;


const Container = styled.div`
  background-color: #AAABB3;
  height: 600px;
  @media ${({theme}) => theme.device.tabletL} {
    height: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    height: 450px;
  }
  @media ${({theme}) => theme.device.mobile} {
    height: 320px;
  }
`;

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.device.tabletL} {
    height: 500px;
  }
  @media ${({theme}) => theme.device.tablet} {
    height: 450px;
  }
  @media ${({theme}) => theme.device.mobile} {
    height: 320px;
  }
  div {
    position: absolute;
    max-width: 1700px;
    width: 100%;
    margin-top: 90px;
    padding: 0 50px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    letter-spacing: 5px;
    
    white-space:pre-wrap;
    animation: fadeInUp 1.5s;
    @media ${({theme}) => theme.device.tablet} {
      padding: 0 20px;
    }
    @keyframes fadeInUp {
      0% {
        opacity: 0;
        transform: translate3d(0, 50%, 0);
      }
      to {
        opacity: 1;
        transform: translateZ(0);
      }
    }
    h1 {
      font-size: 52px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 1px;
      padding-top: 10px;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 48px;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 38px;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 23px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        font-size: 22px;
      }
    }
    h4 {
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      letter-spacing: 0.1px;
      padding-top: 40px;
      line-height: 40px;
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 28px;
        padding-top: 30px;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 20px;
        line-height: 30px;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 16px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        font-size: 12px;
        line-height: 20px;
      }
    }
    p {
      font-size: 20px;
      color: rgb(234, 84, 19);
      @media ${({theme}) => theme.device.tabletL} {
        font-size: 18px;
      }
      @media ${({theme}) => theme.device.tablet} {
        font-size: 14px;
      }
      @media ${({theme}) => theme.device.mobile} {
        font-size: 12px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        font-size: 10px;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 260px;
      background-color: rgb(234, 84, 19);
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      margin-top: 80px;
      transition: all 0.5s linear;
      @media ${({theme}) => theme.device.tabletL} {
        margin-top: 50px;
      }
      @media ${({theme}) => theme.device.tablet} {
        width: 200px;
        padding: 10px;
        font-size: 14px;
      }
      @media ${({theme}) => theme.device.mobile} {
        width: 170px;
        padding: 10px;
        font-size: 10px;
        margin-top:10px;
      }
      @media ${({theme}) => theme.device.mobileS} {
        width: 150px;
      }
        &:hover {
          transform: translateX( 20px )
        }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        p {
          color: white;
          font-size: 16px;
          padding: 0 0 0 5px;
          @media ${({theme}) => theme.device.tablet} {
            font-size: 14px;
          }
          @media ${({theme}) => theme.device.mobile} {
            font-size: 10px;
          }
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
